@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.changePassword-modal {
  .ant-modal-body {
    @include flex-column-center();
    padding: 50px 100px;
  }
  .input-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 48px;
    .tc-btn {
      margin: 0;
    }
  }

  .btn-center {
    justify-content: center !important;
  }
}
