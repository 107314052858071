@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.nf-btn {
  cursor: pointer;
  margin: $btn-margin-vertical $btn-margin-horizontal;
  border-radius: $btn-border-radius;
  font-size: $font-size-ssm;
  width: $btn-width;
  height: $btn-height;
  align-items: center;
  text-align: center;
  &:hover {
    opacity:0.5;
  }
  &:active {
    opacity: $btn-active-opacity;
  }
  &:disabled {
    &:hover {
      cursor: not-allowed;
      opacity:1;
    }
  }
}

.nf-btn-link {
  position: absolute;
  right: 0;
  bottom: -10px;
  text-decoration: underline;
  font-size: $font-size-sssm;
  width: 140px;
  margin: $btn-margin-vertical 0;
}
.nf-btn-small {
  font-size: $font-size-sssm;
}
.nf-btn-table {
  width: $btn-table-width;
  height: $btn-table-height;
  font-size: $font-size-ssm;
  border: $btn-wider-border;
  margin: $btn-wider-margin;
}
.nf-btn-wider {
  width: $btn-wider-width;
  border: $btn-wider-border;
  margin: $btn-wider-margin;
}

.nf-btn-black {
  background-color: $black;
  color: $white;
}

.nf-btn-disabled:not(.nf-btn-link) {
  background-color: $light-grey !important;
  border-color: $light-grey !important;
  color: #7B7B7B;
  border-radius: 3px;
}

.nf-btn-light-red:not(.nf-btn-link) {
  background-color: $shallow-red !important;
  border-color: $shallow-red !important;
  color: $white;
}

.nf-btn-white {
  background-color: $white;
  color: $black;
  border: $btn-wider-border;
}

.nf-btn-red {
  background-color: $dark-red;
  border-color: $dark-red;
  color: $white;
}

.nf-btn-white {
  background-color: $white;
  color: $black;
}

.nf-btn-black.nf-btn-wider {
  border-color: $black !important;
}
