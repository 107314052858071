@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.qr-modal {
  .pre-label {
    width: 130px;
    font-size: $font-size-ssm;
    display: inline-block;
    &.label-required {
      @include requiredItem();
    }
  }
  .text-area {
    display: flex;
    margin-top: 24px;
    align-items: center;
    position: relative;
    font-size: $font-size-sm;
    .label-required {
      margin-bottom: 2px;
    }
    label {
      align-self: flex-start;
      padding-top: 2px;
      display: inline-block;
      font-size: $font-size-ssm;
      width: 130px;
    }
  }
}
