@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.coupon-modal {
  .file-type-radio-btn-area {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 16px;
  }
  .font-sm {
    font-size: $font-size-sm;
  }
  .select_coupon_type {
    margin-bottom: 5px;
    font-size: $font-size-sm;
    background-color: $tc-white;
    border-radius: 5px;
    .ant-select-selection-item {
      font-size: $font-size-sm;
      text-overflow: ellipsis;
      border: 1px solid transparent;
    }
    &.ant-select-focused {
      transition: box-shadow 0.2s;
      border-color: $tc-gray-d1 !important;
      box-shadow: 0 0 0 1px black;
    }
    .ant-select-selector {
      width: 250px;
      border-color: $tc-gray-d1 !important;
      border-radius: 5px;
      background-color: transparent !important;
    }
    .ant-select-selection-placeholder {
      font-size: $font-size-ssm !important;
    }
    .ant-select-clear {
      background-color: $tc-white !important;
    }
    // .select-max-limit {
    //   max-width: calc(100% - 130px);
    // }
    .ant-select-arrow {
      background-color: transparent;
    }
    &.select-disabled {
      background-color: $tc-gray-l3;
      .ant-select-selector {
        border: 1px solid transparent !important;
      }
    }
  }
  .pre-label {
    width: 130px;
    min-width: 130px;
    font-size: $font-size-ssm;
    display: inline-block;
    &.label-required {
      @include requiredItem();
    }
    &.label-required-disabled {
      @include requiredDisabledItem();
    }
    &.label-disabled {
      color: $tc-gray-l1;
    }
    &.top {
      align-self: flex-start;
    }
  }
  .label-disabled {
    color: #bdbdbd;
  }
  .shop-tooltip {
    position: absolute;
    left: 80px;
  }
  .image-area {
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    .image-select {
      position: relative;
      height: 200px;
      width: 150px;
      background-color: $grey;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      border-radius: 2px;
      overflow: hidden;
      .tc-btn {
        width: 118px;
        border-width: 2px;
        margin: 0;
        margin-bottom: 17px;
        font-size: $font-size-ssm;
      }
      .close-icon {
        position: absolute;
        right: 3px;
        top: 3px;
        font-size: 18px !important;
        z-index: 1;
      }
    }
  }
  .detail-area-title {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    border-top: solid 1px #efe6e5;
    border-bottom: solid 1px #efe6e5;
    cursor: pointer;
  }
  .text-area {
    width: 100%;
    display: flex;
    margin-top: 25px;
    margin-right: 30px;
    align-items: center;
    position: relative;
    label {
      align-self: flex-start;
      padding-top: 5px;
      display: inline-block;
      font-size: $font-size-ssm;
      width: 130px;
      cursor: text;
      flex-shrink: 0;
    }
  }
  .date-area {
    display: flex;
    margin-top: 25px;
    align-items: center;
    position: relative;
    .label-required {
      margin-bottom: 2px;
    }
  }
  .check-area {
    display: flex;
    margin-top: 24px;
    align-items: center;
    position: relative;
    // label {
    // font-size: $font-size-ssm;
    // }
    .input-line {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      justify-content: space-between;
    }
    .tc-select-error {
      position: absolute;
      height: auto;
      right: 0px;
      color: $tc-red;
      font-size: $font-size-sssm;
      top: -24px;
      margin-top: 8px;
    }
    .use-count-container {
      position: relative;
      display: flex;
      align-items: center;
    }
    .tc-input-info {
      height: 0;
    }
    .label-required {
      margin-bottom: 2px;
    }
  }
  .file-area {
    display: flex;
    align-items: center;
    position: relative;
    label {
      font-size: $font-size-ssm;
    }
  }
  .coupon-delivery-target-first {
    width: 256px;
  }
  .reserve-count {
    &-area {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 4px;
    }
    &-label {
      width: 130px;
      font-size: 12px;
    }
    &-value {
      width: 100px;
      font-size: 12px;
    }
  }
  .file-type {
    &-area {
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      margin-bottom: 15px;
    }
    &-label {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 15px;
    }
    &-radio-area {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  &-coupon-day-count {
    &-area {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 24px;
      label {
        width: 130px;
        font-size: 12px;
      }
    }
  }
}
