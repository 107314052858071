@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.selectColumsModal-content {
  .contents-area {
    margin-top: 10px;
    margin-left: 5%;
    margin-right: 5%;
    .all-check-box {
      label {
        font-size: $font-size-ssm;
        font-family: bold;
      }
      margin-top: 20px;
      margin-bottom: 15px;
    }
    .checkbox-group {
      margin-top: 20px;
      .checkbox-line {
        display: flex;
        .check-box {
          label {
            font-size: $font-size-ssm;
          }
          margin-bottom: 15px;
        }
      }
    }
  }
  .button-area {
    display: flex;
    margin: 0 auto;
    justify-content: center;
  }
}
