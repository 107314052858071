@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.shop-modal {
  .radius-btn {
    .tc-btn {
      width: 60px;
      margin: 0;
      margin-top: 29px;
      margin-left: 10px;
    }
  }
  .map-wrapper {
    margin-left: 130px;
  }
  .input-time {
    .ant-input-affix-wrapper:nth-child(2) {
      width: 130px;
    }
    .tc-input-wrapper:nth-child(2) {
      margin-right: 0;
      .pre-label {
        width: 30px;
        min-width: 30px;
        text-align: center;
      }
      max-width: 450px;
    }
    .tc-input-wrapper:nth-child(3) {
      margin-right: 0;
      max-width: 450px;
      width: 100% !important;
    }
  }

  .input-location {
    .tc-input-wrapper:nth-child(2) {
      .sub-label {
        margin: 0 -10px 0 15px;
      }
      margin-right: 0;
    }
    .tc-input-info {
      justify-content: flex-end;
    }
  }
  .input-checkin-flag {
    .pre-label {
      width: 130px;
      font-size: $font-size-ssm;
      display: inline-block;
      &.label-required {
        @include requiredItem();
      }
    }
    margin-bottom: -20px;
    display: flex;
    .tc-checkbox-wrapper {
      width: 93px;
    }
  }
}
