@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.tc-modal-information {
  text-align: left;
  align-self: center;
  width: 100%;
  padding: 0 20px;
  .tc-modal-information-title-area {
    margin: 0 44px;
    border-bottom: solid 1px #efe6e5;
    .tc-modal-information-date {
      text-align: left;
      margin-top: 20px;
      font-size: $font-size-sm;
      color: $light-grey;
    }
    .tc-modal-information-title {
      align-self: flex-start;
      text-align: left;
      font-weight: bold;
      font-size: $font-size-lg;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .tc-modal-information-contens {
    text-align: left;
    margin-top: 20px;
    font-size: $font-size-ssm;
    white-space: pre-line;
    overflow-x: auto;
  }
  .button-container {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
