@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.file-upload-area,
.upload-tip {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: $font-size-ssssm;
}
.file-upload-area {
  background-color: $grey;
  right: 44px;
  top: 20px;
  position: absolute;
  align-items: center;
  width: 300px;
  height: 220px;
  border-radius: 10px;
  .avator {
    margin: 30px 0 20px;
    img {
      width: 100px;
      height: 100px;
      border-radius: 50px;
    }
    &:hover {
      cursor: pointer;
    }
    .avater,
    .ant-image-mask {
      border-radius: 50px;
      font-size: $font-size-ssm;
    }
  }
  &.read-only {
    height: 170px;
  }
  .upload-tip {
    margin-bottom: 30px;
    user-select: none;
  }
  .image-icon {
    position: relative;
    text-align: center;
  }
  .image-text {
    font-size: $font-size-sssm;
    text-align: center;
  }
}
