@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.circle-title {
  display: flex;
  align-items: center;
  .title {
    margin-left: 5px;
  }
}
