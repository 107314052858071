@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.legend-container {
  display: flex;
  font-size: $font-size-ssm;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  .legend-list {
    display: flex;
    margin-right: 20px;
    width: 90%;
    .legend-list-item {
      display: flex;
      align-items: center;
      width: auto;
      white-space: nowrap;
      .item {
        margin-right: 10px;
        .left {
          margin-left: 5px;
        }
      }
      .dot {
        min-width: 10px;
      }
    }
  }
  .legend-area {
    display: flex;
    align-items: center;
    position: relative;
    right: 20px;
    .page-count {
      margin: 0 4px;
    }
  }
}

.chart-container {
  width: 95%;
  overflow: visible;
  rect[fill='none'] {
    display: none;
  }

  ellipse {
    stroke: none;
  }
  ellipse:hover {
    stroke-opacity: 0.5;
    stroke-width: 1.5px;
    stroke: $light-black;
  }
  path {
    stroke: none;
  }
  path:hover {
    stroke-opacity: 0.5;
    stroke-width: 1.5px;
    stroke: $light-black;
  }
  svg {
    overflow: visible !important;
  }
}
