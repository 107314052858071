@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.map-element_area, .map-element_select-field-area {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;

  .map-element_select-field {
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;

    .cus-pre-label::after {
      content: '*';
      color: $tc-red;
      font-size: $font-size-sm;
    }

    .map-element_select-field-label {
      font-size: 0.75rem;
    }
    .map-element_select-field-label::after {
      font-size: 0.875rem;
      content: '*';
      color: #9b233f;
    }
  }

  .map-element_area-map-area {
    width: 100%;
    .add-btn-area {
      padding-top: 5px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 100%;
    }
  }
}