@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.layout-modal {
  user-select: none;
  h2 {
    font-weight: bold;
    margin-top: 40px;
    margin-left: 10px;
  }
  .layout-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .layout-box {
      width: 250px;
      margin: 10px;
      display: flex;
      @include shadow-box();
      flex-direction: column;
      align-items: center;
      border-radius: 10px;
      &:hover {
        cursor: pointer;
      }
      &.layout-box-active {
        background-color: $grey;
        div.layout-plus {
          background-color: #bcbcbc;
        }
        div > h3 {
          color: $black;
        }
      }
      .layout-title {
        margin: 30px 0 30px;
        h3 {
          font-weight: bold;
          color: $dark-grey;
        }
      }
      .layout-plus-container {
        &.col-1 {
          max-width: 75px;
        }
        &.col-2 {
          max-width: 140px;
        }
        &.col-3 {
          max-width: 205px;
        }
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 40px;
        .layout-plus {
          color: $dark-grey;
          background-color: $grey;
          height: 65px;
          width: 65px;
          text-align: center;
          line-height: 65px;
          margin: 1px;
          .anticon {
            font-size: 10px;
          }
        }
        .plus {
          height: 132px;
          width: 132px;
          line-height: 132px;
          text-align: center;
        }
      }
    }
  }
}
