@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.staff-modal {
  .auth-title {
    display: flex;
    margin-bottom: 8px;
  }
  .input-title {
    margin-top: 40px;
  }
  h3 {
    margin-top: 20px;
    .auth-tip {
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      font-size: $font-size-ssssm;
      flex: 1;
      color: $dark-grey;
      font-weight: normal;
    }
  }
  h3:last-child {
    background-color: black;
  }
  .input-checkbox {
    margin-bottom: -20px;
  }
  .input-menu {
    margin-top: 30px;
    height: auto;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
