@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.tc-select-wrapper {
  font-size: $font-size-ssm;
  .tc-select-preLabel {
    min-width: 130px;
    display: inline-block;
    &.label-required {
      @include requiredItem();
    }
    &.label-required-disabled {
      @include requiredDisabledItem();
    }
    &.label-disabled {
      color: $tc-gray-l1;
    }
  }
  .tc-select-label-area {
    align-items: baseline;
    margin-bottom: 5px;
    min-height: 24px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    &.flex-end {
      justify-content: flex-end;
    }
    .tc-select-label {
      color: $tc-black;
      line-height: 1.5;
      &.label-bold {
        font-weight: bold;
      }
      &.label-disabled {
        color: $tc-gray-l1;
      }
      &.label-required {
        @include requiredItem();
      }
    }
    .tc-select-error {
      color: $tc-red;
      font-size: $font-size-sssm;
    }
  }
  .tc-select-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 32px;
    .prefix {
      margin-right: 10px;
      &.label-disabled {
        color: $tc-gray-l1;
      }
      &.label-required {
        @include requiredItem();
      }
    }
    .suffix {
      margin-left: 10px;
      &.label-disabled {
        color: $tc-gray-l1;
      }
      &.label-required {
        @include requiredItem();
      }
    }
    .uneditable {
      font-size: $font-size-sm;
    }
    .tc-select {
      width: 100%;
      font-size: $font-size-sm;
      background-color: $tc-white;
      border-radius: 5px;
      .ant-select-selection-item {
        font-size: $font-size-sm;
        text-overflow: ellipsis;
        border: 1px solid transparent;
      }
      &.ant-select-focused {
        transition: box-shadow 0.2s;
        border-color: $tc-gray-d1 !important;
        box-shadow: 0 0 0 1px black;
      }
      .ant-select-selector {
        border-color: $tc-gray-d1 !important;
        border-radius: 5px;
        background-color: transparent !important;
      }
      .ant-select-selection-placeholder {
        font-size: $font-size-ssm !important;
      }
      .ant-select-clear {
        background-color: $tc-white !important;
      }
      // .select-max-limit {
      //   max-width: calc(100% - 130px);
      // }
      .ant-select-arrow {
        background-color: transparent;
      }
      &.select-disabled {
        background-color: $tc-gray-l3;
        .ant-select-selector {
          border: 1px solid transparent !important;
        }
      }
    }
  }
}
