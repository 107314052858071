@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.top-image {
	.summary-area {
		display: flex;
		flex-direction: column;
		align-items: center;
		.disable-btn {
			display: flex;
			justify-content: center;
			padding: $btn-padding;
			border-radius: $btn-border-radius;
			font-size: $font-size-ssm;
			height: $btn-height;
			align-items: center;
			text-align: center;
			font-weight: bold;
			border-color: $light-grey !important;
			background-color: $white;
			color: $light-grey;
			border: $btn-wider-border;
			width: 100px;
			.btn-icon {
				width: 20px;
				height: 20px;
			}
		}
		.summary-item-area {
			margin-bottom: 10px;
		}
		.tc-btn {
			width: 100px;
			margin: 0;
			background-color: $white !important;
		}
	}
}