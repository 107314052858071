@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.product-search-area {
    margin: 40px 15px 0;
    .search-title {
        font-size: $font-size-base;
        font-weight: bold;
    }
    .infinite-scroll-component::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
    .infinite-scroll-component::-webkit-scrollbar-thumb {
        background: $dark-grey;
        border-radius: 5px;
    }
    .infinite-scroll-component::-webkit-scrollbar-track {
        background: lighten($light-grey, 17%);
        border-radius: 5px;
    }
    .product-search-content {
        margin-top: 10px;
        margin-bottom: 30px;
        display: flex;
        .search-condition {
            display: flex;
            flex-direction: column;
            width: 340px;
            .nf-input-label {
                font-size: $font-size-sssm;
                white-space: pre-line;
            }
        }
        .search-button-container {
            margin-top: 50px;
            display: flex;
            justify-content: center;
        }
        .search-result {
            &:hover {
                cursor: pointer;
            }
            @include shadow-box();
            flex: 1;
            padding: 30px 30px;
            margin-left: 40px;
            margin-bottom: 30px;
            height: 320px;
            width: 500px;
            .result-title {
                font-weight: bold;
                margin-bottom: 40px;
            }
            .result-box {
                width: 150px;
                display: flex;
                flex-direction: column;
                @include shadow-box();
                border: $shallow-grey solid 1px;
                background-color: $white;
                margin-right: 10px;
                margin-top: 10px;
                padding: 10px 10px 5px;
                &.result-box-selected {
                    background-color: $grey;
                    .result-info {
                        font-weight: bold;
                    }
                }
            }
            .result-info {
                display: flex;
                margin-top: 2px;
                flex-direction: column;
                font-size: $font-size-sssm;
                line-height: 13px;
                .product-name {
                    @include maxRows();
                }
            }
            .chunk-list {
                width: 450px;
                display: flex;
            }
        }
    }
}