@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.download-page {
  .graph-area {
    font-size: $font-size-ssm;
    background: $white;
    box-shadow: 0px 0px 24px $transparent-black;
    border-radius: 8px;
    margin-bottom: 10px;
    .legend {
      margin: 20px 10px 10px 40px;
      display: flex;
      align-items: center;
      .circle-title {
        margin-left: 20px;
      }
    }
    .count {
      display: flex;
      text-align: left;
      margin: 20px 20px;
      height: 60px;
      font-size: $font-size-ssm;
      background: $white;
      box-shadow: 0px 0px 24px $transparent-black;
      border-radius: 12px;
      align-items: center;
      justify-content: space-between;
      .main {
        display: flex;
        align-items: center;
        margin-left: 20px;
        .circle {
          font-size: $font-size-xxl;
        }
        .title {
          margin-left: 5px;
        }
      }
      .sub {
        font-size: $font-size-lm;
        font-weight: bold;
        margin-right: 20px;
        padding-left: 50px;
      }
      .blue {
        color: #14789c;
      }
      .green {
        color: #149c75;
      }
    }
    .data-none {
      height: 300px;
      text-align: center;
      align-self: center;
    }
  }
  .download-title-area {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;

    .download-title-name {
      width: fit-content;
      flex-shrink: 0;
    }

    .download-count-area {
      position: absolute;
      left: 178px;
      display: flex;
      flex-shrink: 0;
      justify-content: flex-start;
      align-items: flex-end;
      gap: 8px;
      height: 26px;
      width: 550px;

      span {
        font-size: 14px;
        font-weight: normal;
        line-height: 16px;
        width: fit-content;
      }
      .download-count {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        font-size: 20px;
        font-weight: bold;
        line-height: 20px;
      }
    }
  }
}
