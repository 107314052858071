@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.tc-input-wrapper {
  .tc-wrapper-post-label {
    // display: flex;
    // .tc-container-post-label {
    //   width: 100%;
    // }
    .post-label {
      margin-top: 27px;
      margin-left: 7px;
      &.label-disabled {
        color: $light-grey;
      }
    }
  }
  .suffix-icon {
    width: 18px;
    height: 18px;
  }
  .ant-input-affix-wrapper {
    background-color: $grey;
    border: none;
  }
  // margin: 10px 10px;
  &.pre-label-exist {
    margin: 10px 0;
  }
  &.tc-input-disabled {
    .ant-input[disabled] {
      background-color: $white;
      border: 1px solid #c4c2c3 !important;
    }
  }
  font-size: $font-size-ssm;
  .tc-input-info {
    height: 15px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    &.flex-end {
      justify-content: flex-end;
    }
  }
  .tc-input-line {
    display: flex;
    align-items: center;
    line-height: 22px;
    .font-sm {
      font-size: $font-size-sm;
    }
    .sub-label {
      width: 60px;
    }
    ::placeholder {
      transform: translateY(-0.5px);
    }
  }
  .tc-input-label {
    .label-bold {
      font-weight: bold;
    }
    &.label-disabled {
      color: $light-grey;
    }
  }

  .pre-label {
    width: 130px;
    display: inline-block;
    &.label-required {
      @include requiredItem();
      margin-bottom: 2px;
    }
    &.label-disabled {
      color: $light-grey;
    }
  }
  .pre-label:not(.tc-range-picker .pre-label) {
    min-width: 130px;
  }
  .tc-input-error {
    color: $cardinal;
    font-size: $font-size-sssm;
  }
  .date-error {
    position: absolute;
    bottom: 30px;
    right: 1px;
  }
  input {
    background-color: $grey;
    font-size: $font-size-sm;
    &::placeholder {
      padding-left: $input-placeholder-padding-left;
      font-size: $font-size-ssm;
    }
  }
  .tc-select {
    width: 100% !important;
    font-size: $font-size-ssm;
    background-color: $grey;
    border-radius: 2px;
    .ant-select-selection-item {
      font-size: $font-size-ssm;
      text-overflow: ellipsis;
    }
    .ant-select-selector {
      border-radius: 2px;
      margin-right: 0px;
      .ant-select-selection-item-content {
        font-size: $font-size-sm;
      }
      .ant-select-selection-item {
        font-size: $font-size-sm;
      }
    }
    &.select-block-grey,
    &.select-inline-grey {
      font-size: $font-size-sssm;
      .ant-select-selector {
        background-color: $grey;
        margin: $input-block-margin;
      }
      .ant-select-clear,
      .anticon-close-circle {
        background-color: $grey;
      }
      &.ant-select-multiple .ant-select-selection-item {
        background-color: $white;
      }
    }
    &.select-block-grey-normal {
      font-size: $font-size-ssm;
      .ant-select-selector {
        background-color: $grey;
        margin: 8px 0px;
      }
      .ant-select-clear,
      .anticon-close-circle {
        background-color: $grey;
      }
    }
    &.select-max-limit {
      max-width: calc(100% - 130px);
    }

    &.select-inline-grey {
      width: 175px;
    }
    &.select-disabled {
      .ant-select-selector {
        background-color: $white;
        border: 1px solid #c4c2c3 !important;
      }
    }
    &.select-disabled-theme {
      .ant-select-arrow {
        background-color: transparent;
      }
    }
  }
}
