@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.brand-select {
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    cursor: pointer !important;
  }
  .ant-select-selection-overflow-item,
  .ant-select-selection-overflow,
  .ant-select-selection-item,
  .ant-select-selection-item-content {
    pointer-events: none !important;
    cursor: pointer !important;
  }
}
