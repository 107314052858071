@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.lottery {
  &-reference-coupone-area {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .lottery-reference-coupon-text {
      margin-top: 25px;
      font-size: 0.75rem;
      word-wrap: break-word;
      min-width: 150px;
    }
    .lottery-reference-modal-coupon-list {
      width: 770px;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 0 0;

      .lottery-reference-modal-coupon-border {
        margin-top: 25px;
        width: 100%;
      }
    }
  }
  &-reference-modal-image {
    width: 770px;
    display: flex;
    flex-direction: row;
    margin-top: 25px;

    div {
      .referenceImageRow {
        justify-content: flex-start;
        column-gap: 5px;

        div {
          .referenceImageBox {
            background-color: transparent;
          }
        }
      }
    }

    .image-cell {
      position: relative;
      height: 150px;
      width: 19%;
      margin-right: 2px;
      background-color: #f5f5f5;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 40px;
      border-radius: 2px;
    }
  }

  &-coupon-area {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .lottery-coupon-label {
      margin-top: 25px;
      font-size: 0.75rem;
      word-wrap: break-word;
      min-width: 150px;
      &::after {
        content: "*";
        color: #990000;
        font-size: 0.875rem;
      }
    }

    .coupon-list {
      margin-top: 0;
      width: 100%;
    }
  }

  &-space-left {
    margin-left: 10px;
  }

  &-push-area-display {
    width: 100%;
    display: flex;
    align-items: flex-start;
    line-height: 22px;
    margin-top: 30px;

    .lottery-push-area-display-label {
      font-size: 0.75rem;
      word-wrap: break-word;
      min-width: 150px;
      &::after {
        content: "*";
        color: #990000;
        font-size: 0.875rem;
      }
    }

    .push-area-display-label, .push-area-display-text {
      font-size: 0.75rem;
      word-wrap: break-word;
    }

    .push-area-display-label {
      margin-right: 20px;
      width: 120px;
    }
  }

  &-push-btn-area-display {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;

    .push-area-display {
      display: flex;
    }
  }

  &-modal-update-coupon {
    display: flex;
    align-items: center;
    margin-top: 30px;

    .lottery-label-required::after {
      content: "*";
      color: #990000;
      font-size: 0.875rem;
    }

    .lottery-coupon_code,
    .lottery-text-after-code {
      font-size: 14px;
      line-height: 1.5715;
      word-wrap: break-word;
      margin-left: 10px;
    }

    .lottery-winner_count {
      width: 15%;
      font-size: 14px;
      line-height: 32px;
      word-wrap: break-word;
      background-color: #f5f5f5;
      border-radius: 2px;
      padding-left: 11px;
    }
  }

  &-coupon-list-winner_count {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 3px;
    margin-top: 25px;

    .tc-input-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .tc-input-label-area {
        line-height: 32px;
        .tc-input-label {
          width: 110px;
          line-height: 32px;
        }
      }
    }

    span {
      font-size: 14px;
      line-height: 1.5715;
      word-wrap: break-word;
    }
  }

  &-image-display-area {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 30px;

    .image-area-error {
      color: #990000;
      font-size: 0.625rem;
      width: 300px;
      text-align: right;
    }
  }

  &-push-area {
    display: flex;
    align-items: flex-start;
    margin-top: 30px;
    height: 95px;

    .pre-label {
      width: 150px;
      min-width: 150px;
      font-size: 0.75rem;

      &.label-required::after {
        content: "*";
        color: #990000;
        font-size: 0.875rem;
      }
    }
  }

  &-push-btn-area {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;

    .cus-input-line.input-checkbox {
      margin-top: 0;
      line-height: 20px;
      height: 20px;
    }
  }

  &-input-area {
    .tc-input-wrapper .pre-label:not(.tc-range-picker .pre-label) {
      min-width: 150px;
    }
    .cus-input-line .input-textarea {
      .cus-pre-label {
        min-width: 150px;
      }
    }
    .cus-input-line.input-images {
      .cus-pre-label {
        min-width: 150px;
      }
    }
    .code-area {
      display: flex;
      align-items: center;
      margin-top: 30px;

      .pre-label {
        min-width: 150px;
        font-size: 0.75rem;
      }
    }
    .date-area {
      display: flex;
      align-items: center;
      position: relative;

      .lottery-modal_date-area {
        .tc-range-picker-line {
          .tc-range-picker-preLabel {
            width: 150px;
          }
        }
      }
    }

    .lottery-date-area {
      line-height: 52px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      .cus-input-line.input-checkbox {
        margin-top: 0;
      }
    }
  }
}

.lottery-modal {
  .image-area {
    display: flex;
    flex-direction: row;
    .pre-label {
      margin-top: 5px;
      width: 150px;
      min-width: 150px;
      display: inline-block;
      font-size: 0.75rem;
      &.label-required::after {
        content: "*";
        color: #990000;
        font-size: 0.875rem;
      }
    }
    .image-select {
      position: relative;
      height: 150px;
      width: 150px;
      background-color: $grey;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      border-radius: 2px;
      overflow: hidden;
      .tc-btn {
        width: 118px;
        border-width: 2px;
        margin: 0;
        margin-bottom: 17px;
        font-size: $font-size-ssm;
      }
      .close-icon {
        position: absolute;
        right: 3px;
        top: 3px;
        font-size: 18px !important;
        z-index: 1;
      }
    }
  }
}

.coupon-list {
  margin-top: 30px;
  .coupon-border {
    border: 1px solid #c4c2c3;
    border-radius: 8px;
    margin-top: 30px;
    .coupon-input-area {

      padding-inline: 20px;
      padding-bottom: 30px;

      .tc-select-wrapper {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        align-items: center;

        .tc-select-line {
          width: 100%;
        }

        .tc-select-label-area {
          .tc-select-label {
            width: 110px;
          }
        }
      }


      .pre-label {
        min-width: 110px !important;
        &.label-required {
          @include requiredItem();
        }
      }
      .cus-pre-label {
        width: 110px;
        min-width: 110px;
      }
    }

    .delete-btn-area {
      display: flex;
      justify-content: flex-end;
      margin-right: 10px;
      min-height: 26px;
      margin-top: 10px;
    }
  }

  .add-btn-area {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    .plus-icon {
      width: 28px;
      height: 28px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.lottery-reference-modal {
  &-coupon-list {
    margin-top: 30px;
    margin-left: 10px;
  }
  &-coupon-border {
    border: 1px solid #c4c2c3;
    border-radius: 8px;
    margin-top: 30px;
    padding: 30px 20px;
  }
  &-coupon {
    width: 100%;
    line-height: 22px;
    display: flex;
    align-items: center;
  }
  &-label {
    width: 120px;
    font-size: 0.75rem;
    word-wrap: break-word;
  }
  &-text {
    margin-right: 10px;
  }

  &-template-area {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 30px;
  }
  &-template-label {
    width: 150px;
  }
}

.lottery-modal_new_input_area {
  .tc-input-wrapper, .tc-select-wrapper {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 25px;
    position: relative;

    .tc-input-label-area, .tc-select-label-area {
      width: 150px;
      flex-shrink: 0;

      .tc-input-error {
        position: absolute;
        top: -14px;
        right: 0;
      }
    }

    .tc-input-line {
      width: 100%;
    }

    .tc-select-line {
      width: 270px;
      flex-shrink: 0;
    }
  }
}

.lottery-modal_update_date {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  .lottery-modal_update_date_label {
    width: 150px;
    line-height: 32px;
    font-size: 12px;

    &::after {
      content: "*";
      color: #990000;
      font-size: 0.875rem;
    }
  }

  .lottery-modal_update_date_area {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 15px;

    .lottery-modal_update_date_text {
      line-height: 32px;
      font-size: 12px;
    }
  }
}

.lottery-modal_reference_label {
  width: 150px;
  flex-shrink: 0;
  line-height: 32px;
  font-size: 12px;
}
