@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.referenceImageRow {
  display: flex;
  justify-content: space-between;
  .referenceImageBox {
    position: relative;
    display: inline-block;
    background-color: $grey;
    padding-bottom: '3rem';
    border-radius: 2px;
    overflow: hidden;

    &.empty {
      background-color: white;
    }
  }
  .referenceImageText {
    text-align: center;
  }
}
