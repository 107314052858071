@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.bounds-select-modal {
  user-select: none;

  .bounds-select-container {
    width: 70%;
    margin: 0 auto;
    .bounds-select-area {
      margin-top: 20px;
      border: 1px solid lightgrey;
    }
  }
  .tc-btn {
    margin: 16px;
  }

  .deselection {
    text-align: right;
    .deselection-button {
      background: none;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      text-decoration: underline;
      text-underline-offset: 2px;
      font-size: $font-size-sssm;
      &:hover,
      &:focus {
        outline: none;
        text-decoration: underline;
      }
      &:active {
        color: $cardinal;
      }
    }
  }

  .buttons-row {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
