@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.google-visualization-tooltip {
  height: auto !important;
  width: auto !important;
  background-color: #3e373999 !important;
  color: white !important;
  padding: 10px !important;
  border-radius: 8px !important;
  border: none !important;
  .graph-tooltip-date {
    text-align: left;
  }
}

.top-container {
  padding-top: 30px;
  .information-area {
    width: 100%;
    font-size: $font-size-ssm;
    background: $white;
    box-shadow: 0px 0px 24px $transparent-black;
    border-radius: 12px;
    margin: 0px;
    padding-left: 0;
    .information-header {
      display: flex;
      align-items: center;
      font-size: $font-size-lm;
      font-weight: bold;
      padding: 20px 20px 10px 20px;
      border-bottom: solid 2px $light-grey;
      .information-icon {
        font-size: 20px;
      }
      div {
        margin-left: 5px;
      }
    }
    .information-border {
      height: 1px;
      width: auto;
      background: $transparent-black;
      margin: 0 15px;
    }
    .information-body {
      height: 210px;
      overflow-y: auto;
      margin-top: 5px;
      margin-right: 5px;
      .information-line {
        height: 40px;
        line-height: 40px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
        margin-right: 20px;
        span {
          padding-left: 10px;
        }
      }
      .infomation-date {
        color: $silver;
        margin-left: 20px;
      }
      .infomation-title {
        color: $light-black;
      }
    }
    .information-body::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    .information-body::-webkit-scrollbar-thumb {
      background: $dark-grey;
      border-radius: 5px;
    }
    .information-body::-webkit-scrollbar-track {
      // background: lighten($light-grey, 17%);
      border-radius: 5px;
    }
    .ant-empty {
      margin-top: 60px;
    }
  }

  .bottom-area {
    width: 100%;
    font-size: $font-size-ssm;
    padding-left: 0;
    display: flex;
    .download-area {
      background: $white;
      box-shadow: 0px 0px 24px $transparent-black;
      border-radius: 12px;
      margin-top: 30px;
      padding-left: 0;
      .download-title {
        display: flex;
        align-items: center;
        font-size: $font-size-lm;
        font-weight: bold;
        padding: 20px 0 0 20px;
        width: auto;
        .download-icon {
          font-size: 20px;
        }
        div {
          margin-left: 5px;
        }
      }
      .download-bottom {
        display: flex;
        margin-bottom: 10px;
        .summary {
          min-height: 340px;
          min-width: 250px;
          margin: 50px 20px;
          font-size: $font-size-ssm;
          background: $white;
          box-shadow: 0px 0px 24px $transparent-black;
          border-radius: 12px;
          padding-left: 0;
          &.graph-none {
            position: absolute;
          }
          .count {
            display: flex;
            text-align: left;
            margin: 20px 20px 0;
            height: 70px;
            font-size: $font-size-ssm;
            background: $white;
            box-shadow: 0px 0px 24px $transparent-black;
            border-radius: 12px;
            align-items: center;
            justify-content: space-between;
            .circle-title {
              margin-left: 20px;
              margin-right: 20px;
            }
            .sub {
              font-size: $font-size-lm;
              font-weight: bold;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin-right: 20px;
            }
            .blue {
              color: #14789c;
            }
            .green {
              color: #149c75;
            }
            .red {
              color: #9b233f;
            }
          }
          .date {
            display: flex;
            margin: 10px 15px;
            margin-left: 20px;
            align-items: center;
            .bold {
              font-size: $font-size-lm;
              font-weight: bold;
              white-space: nowrap;
            }
            .sub {
              margin-top: 5px;
              margin-left: 3px;
              margin-right: 15px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .grey {
              color: $dark-grey;
            }
          }
        }
        .graph-area {
          overflow: auto;
          overflow-y: hidden;
          min-height: 450px;
          min-width: 40%;
          margin-right: 20px;
          &::-webkit-scrollbar {
            width: 10px;
            height: 10px;
          }
          &::-webkit-scrollbar-thumb {
            background: $dark-grey;
            border-radius: 5px;
          }
          &::-webkit-scrollbar-track {
            border-radius: 5px;
          }
          .graph-wrapper {
            min-width: 400px;
            font-size: $font-size-lm;
            align-self: center;
            text-align: center;
            &.graph-none {
              height: 400px;
              width: 100%;
              .ant-empty {
                margin-top: 150px;
              }
            }
            g > text {
              font-size: 10px;
              font-weight: normal;
            }
          }
        }
      }
    }

    .active-user-area {
      background: $white;
      box-shadow: 0px 0px 24px $transparent-black;
      border-radius: 12px;
      margin: 30px 0px;
      min-height: 530px;
      .active-user-title {
        display: flex;
        align-items: center;
        font-size: $font-size-lm;
        font-weight: bold;
        padding: 20px;
        // width: auto;
        min-width: 400px;
        .download-icon {
          font-size: 20px;
        }
        div {
          margin-left: 5px;
        }
        &.none-padding-bottom {
          padding-bottom: 0px;
        }
      }

      .scroll-area {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 460px;
        &::-webkit-scrollbar {
          width: 10px;
          height: 10px;
        }
        &::-webkit-scrollbar-thumb {
          background: $dark-grey;
          border-radius: 5px;
        }
        &::-webkit-scrollbar-track {
          border-radius: 5px;
        }
        &.data-none {
          margin-top: 98px;
        }
        .scroll-item {
          display: flex;
          .space {
            min-width: 20px;
          }
          .record-area {
            display: flex;
            margin: 10px 0px 10px 20px;
            padding-inline: 20px;
            height: 70px;
            min-width: 340px;
            width: calc(100% - 20px);
            font-size: $font-size-ssm;
            box-shadow: 0px 0px 12px $transparent-black;
            border-radius: 12px;
            justify-content: center;
            .record-area-item {
              display: flex;
              align-items: center;
              height: 50px;
              width: 100%;
              .date {
                min-width: 80px;
              }
              .bold {
                font-size: $font-size-lm;
                font-weight: bold;
                white-space: nowrap;
              }
              .sub {
                margin-left: 7px;
                margin-right: 15px;
                margin-top: 5px;
                white-space: nowrap;
              }
              .count-area {
                margin-left: auto;
                margin-top: 18px;
                display: flex;
                align-items: end;
                flex-direction: column;
                .total-user {
                  display: flex;
                  align-items: center;
                  justify-content: end;
                  width: 100%;
                }
                .count-max {
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
                .icon-vector {
                  width: 13px;
                  height: 13px;
                  margin-left: 2px;
                }
                .red {
                  color: #9b233f;
                }
                .diff {
                  display: flex;
                  align-items: center;
                }
                .unit {
                  margin-top: 3px;
                  font-size: $font-size-ssm;
                  margin-left: 2px;
                }
              }
            }
          }
        }
        .grey {
          color: $dark-grey;
        }
      }
    }
  }
  @media (max-width: 1200px) {
    .bottom-area {
      flex-wrap: wrap;
      .active-user-area {
        width: 100%;
        .scroll-area {
          .scroll-item {
            .record-area {
              .record-area-item {
                .sub {
                  margin-left: 12px;
                  margin-right: 15px;
                  min-width: 100px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
                .count-area {
                  min-width: 70px;
                }
              }
            }
          }
        }
      }
    }
  }
  @media (min-width: 1200px) {
    .bottom-area {
      .download-area {
        margin-bottom: 30px;
      }
      .active-user-area {
        margin-left: 15px;
      }
    }
  }
}
