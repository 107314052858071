@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.tc-input-wrapper {
  font-size: $font-size-ssm;
  .tc-input-preLabel {
    min-width: 130px;
    display: inline-block;
    &.label-required {
      @include requiredItem();
    }
    &.label-required-disabled {
      @include requiredDisabledItem();
    }
    &.label-disabled {
      color: $tc-gray-l1;
    }
  }
  .tc-input-label-area {
    align-items: baseline;
    margin-bottom: 5px;
    min-height: 24px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    &.flex-end {
      justify-content: flex-end;
    }
    .tc-input-label {
      color: $tc-black;
      line-height: 1.5;
      &.label-bold {
        font-weight: bold;
      }
      &.label-disabled {
        color: $tc-gray-l1;
      }
      &.label-required {
        @include requiredItem();
      }
    }
    .tc-input-error {
      color: $tc-red;
      font-size: $font-size-sssm;
    }
  }
  .tc-input-line {
    align-items: center;
    .prefix {
      margin-right: 10px;
      text-align: end;
      width: 40px;
      &.label-disabled {
        color: $tc-gray-l1;
      }
      &.label-required {
        @include requiredItem();
      }
    }
    .suffix {
      margin-inline: 10px;
      &.label-disabled {
        color: $tc-gray-l1;
      }
      &.label-required {
        @include requiredItem();
      }
    }
    .uneditable {
      font-size: $font-size-sm;
    }
    ::placeholder {
      transform: translateY(1px);
    }
    input {
      background-color: $tc-white;
      font-size: $font-size-sm;
      border-radius: 5px;
      border-color: $tc-gray-d1 !important;
      &::placeholder {
        font-size: $font-size-ssm;
      }
      &.ant-input[disabled] {
        background-color: $tc-gray-l3;
        border: 1px solid transparent !important;
      }
    }
  }
}
