@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

$scale-plus: 0.47;
$scale-normal: 0.35;
$image-height-normal: 135px;
$image-height-plus: 330px;

.product-info {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 7px 8px;
  overflow: hidden;
  .product-img {
    &.img-size-normal {
      height: $image-height-normal;
    }
    &.img-size-plus {
      height: $image-height-plus;
    }
  }
  &.product-info-device {
    .product-img {
      &.img-size-normal {
        height: $scale-normal * $image-height-normal;
      }
      &.img-size-plus {
        height: $scale-plus * $image-height-plus;
      }
    }
  }
  .product-detail {
    display: flex;
    margin-top: 4px;
    flex-direction: column;
    span {
      @include scale-font-size(0.8);
      font-weight: bold;
    }
    line-height: 10px;

    .product-name {
      @include maxRows(1);
    }
  }
}
