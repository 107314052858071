@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.hmenu-wrapper {
  ::-webkit-scrollbar {
    display: none;
  }
  /* ボタン外側 */
  .hmenu-logo {
    position: relative !important;
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    position: fixed;
    z-index: 600;
    margin-top: 5px;
    margin-left: 20px;
    margin-right: 20px;
  }
  /* ボタン内側 */
  .hmenu-logo span {
    display: inline-block;
    transition: all 0.3s;
    position: absolute;
    left: 14px;
    height: 2px;
    border-radius: 2px;
    background: #383838;
    width: 45%;
    transform: scale(1, 0.9);
  }
  /* 上の線（close時） */
  .hmenu-logo span:nth-of-type(1) {
    top: 15px;
  }
  /* 真ん中の線（close時） */
  .hmenu-logo span:nth-of-type(2) {
    top: 23px;
  }
  /* 下の線（close時） */
  .hmenu-logo span:nth-of-type(3) {
    top: 31px;
  }
  /* 上の線（open時） */
  .hmenu-logo.active span:nth-of-type(1) {
    top: 18px;
    left: 14px;
    transform: translateY(6px) rotate(-45deg);
    width: 50%;
  }
  /* 真ん中の線（open時） */
  .hmenu-logo.active span:nth-of-type(2) {
    opacity: 0;
  }
  /* 下の線（open時） */
  .hmenu-logo.active span:nth-of-type(3) {
    top: 30px;
    left: 14px;
    transform: translateY(-6px) rotate(45deg);
    width: 50%;
  }
  #hmenu {
    display: flex;
    position: absolute;
    top: 65px;
    left: 0;
    height: 0;
    width: 0px;
    z-index: 100;
    font-size: $font-size-ssm;
    color: $paging-active-black;
    background-color: $white;
    transition: 0.3s;
    .hmenu-ul-style {
      opacity: 0;
      transition: 0.3s;
      .bottom-menu {
        opacity: 0;
        transition: 0.3s;
      }
    }
  }
  #hmenu.openmenu {
    display: flex;
    position: absolute;
    padding-top: 65px;
    top: 0px;
    left: 0;
    height: 100vh;
    width: 230px;
    z-index: 100;
    font-size: $font-size-ssm;
    color: $paging-active-black;
    background-color: $white;
    box-shadow: 0px 2px 1px 1px $grey;
    transition: 0.3s;
    overflow-y: scroll;
    overflow-x: hidden;

    a {
      display: block;
      color: $paging-active-black;
      &:hover {
        color: $paging-active-black;
      }
    }
    .hmenu-ul-style {
      opacity: 1;
      transition: all 0.1s steps(1, end);
      width: 230px;
      padding: 0;
      background-color: $white;
      display: flex;
      flex-direction: column;
      .top-menu {
        align-items: flex-start;
      }
      .bottom-menu {
        opacity: 1;
        transition: all 0.3s steps(1, end);
        align-self: flex-end;
        width: 100%;
        margin-top: auto;
        bottom: 10px;
        .link-text {
          color: #7b7b7b;
          text-decoration: underline;
          cursor: pointer;
        }
        li {
          line-height: 30px;
          padding-left: 20px;
          .link-icon {
            margin-left: 3px;
          }
        }
        a {
          color: $silver;
          font-size: $font-size-ssm;
          text-decoration: underline;
        }
      }
      .hmenu-li-style {
        transition: 0.3s;
        line-height: 40px;
        height: 40px;
        justify-content: space-between;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover {
          opacity: 0.5;
        }
        .icon-title {
          display: flex;
          div {
            font-size: 0.95rem;
            white-space: nowrap;
          }
          .menu-icon {
            margin: 10px;
            font-size: 20px;
          }
        }
        .arrow-icon {
          margin: 10px;
          font-size: 20px;
        }
        &.child {
          margin-left: 40px;
          display: block;
          .icon-title {
            font-size: $font-size-ssm;

            .advent-calendar-submenu {
              width: 180px;
              display: flex;
              align-items: center;
              &-text {
                font-size: 12px;
                line-height: 20px;
                width: 100%;
                white-space: normal;
              }
            }
          }
          &.hidden {
            height: 0;
            .icon-title {
              display: none;
            }
          }
        }
      }
      .attentionFlg {
        > a::after,
        > span::after {
          content: '　';
          display: inline-block;
          margin-left: 3px;
          height: 8px;
          width: 8px;
          background: $yellow;
          border-radius: 50%;
          position: relative;
          top: 11px;
        }
      }
    }
  }
}

#hmenu {
  overflow-x: hidden;
}
