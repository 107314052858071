@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.stamp-rally-result-csv-modal {
  margin-left: 0;
  label {
    @include requiredItem();
  }
}
