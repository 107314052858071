@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.stamp-modal {
  .pre-label {
    width: 130px;
    font-size: $font-size-ssm;
    display: inline-block;
    &.label-required {
      @include requiredItem();
    }
  }
  .input-datetime,
  .input-stamp-count,
  .input-point {
    .suffix {
      position: relative;
      top: 11px;
    }
  }
  .input-stamp-rule > .cus-pre-label {
    padding-right: 50px;
  }
  .stamp-rule-label {
    padding-right: 50px;
  }
  .input-datetime,
  .input-line {
    .tc-input-info {
      justify-content: flex-end;
    }
  }
  .input-exchange-area {
    margin-top: -3px;
    margin-bottom: -20px;
  }
  .input-exchange-type {
    display: flex;
    .tc-checkbox-wrapper {
      width: 93px;
    }
  }
  .input-stamp-count,
  .input-point {
    .suffix {
      left: 5px;
    }
  }
  .image-area {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    .image-select {
      position: relative;
      height: 150px;
      width: 150px;
      background-color: $grey;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      .tc-btn {
        width: 118px;
        border-width: 2px;
        margin: 0;
        margin-bottom: 17px;
        font-size: $font-size-ssm;
      }
      .close-icon {
        position: absolute;
        right: 3px;
        top: 3px;
        font-size: 18px !important;
        z-index: 1;
      }
    }
  }
  .detail-area-title {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    border-top: solid 1px #efe6e5;
    border-bottom: solid 1px #efe6e5;
    cursor: pointer;
  }
  .text-area {
    display: flex;
    margin-top: 20px;
    margin-right: 30px;
    align-items: center;
    position: relative;
    label {
      align-self: flex-start;
      padding-top: 5px;
      display: inline-block;
      font-size: $font-size-ssm;
      width: 130px;
    }
  }
  .date-area {
    display: flex;
    margin-top: 20px;
    align-items: center;
    position: relative;
  }
  .check-area {
    display: flex;
    margin-top: 30px;
    position: relative;
    label {
      font-size: $font-size-ssm;
    }
    .input-days-flag {
      display: flex;
      flex-direction: column;
      position: relative;
      bottom: 15px;
      .input-line {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: -10px;
        .tc-checkbox-wrapper {
          margin-left: 0 !important;
        }
        .tc-input-wrapper {
          height: auto;
          padding: 0px 5px;
          margin: 0;
          position: relative;
          bottom: 8px;
        }
      }
    }
  }
  .file-area {
    display: flex;
    align-items: center;
    position: relative;
    label {
      font-size: $font-size-ssm;
    }
  }
  .reference-area {
    display: flex;
    font-size: $font-size-ssm;
    margin: 22px 0;
  }
  .stamp-rule .cus-pre-label {
    padding-right: 50px;
  }
  .input-fixed-phrase {
    margin-top: 0;
  }
  .input-stamp-count {
    margin-top: -5px;
  }
  .imageText {
    font-size: 12px;
  }
  .reference-image-area {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
    label {
      width: 130px;
      font-size: 12px;
    }
    .referenceImageText {
      font-size: 12px;
    }
  }
  .stamp-rally-reference-input-area {
    .text-area {
      .pre-label {
        flex-shrink: 0;
        width: 130px;
      }
    }
  }
}
