@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.personal-icon {
  text-align: center;
  position: relative;
  cursor: pointer;
  .icon-mask {
    position: absolute;
    top: 0;
    opacity: 0;
    background-color: $transparent-black;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  &:hover .icon-mask {
    opacity: 1;
  }
  .icon-caption {
    top: 10px;
    text-align: center;
    color: $white;
  }
}
