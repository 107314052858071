@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.stamp-rally-user-result {
  .subtitle-grey {
    color: #837f80;
  }
}
