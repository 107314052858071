@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.tc-modal-delete {
    .modal-content {
        font-size: $font-size-sssm;
        div {
            text-align: center;
        }
        .delete-title {
            margin-top: 20px;
            margin-bottom: 10px;
            font-weight: bold;
            font-size: $font-size-xl;
        }
        .delete-info {
            font-size: $font-size-ssm;
        }
        .delete-tip {
            color: $dark-red;
            margin-top: 10px;
            font-size: $font-size-ssm;
        }
        .delete-button-area {
            display: flex;
            justify-content: center;
        }
    }
}