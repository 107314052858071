@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.table-title,
.table-title-left,
.table-title-right {
  .plus-icon {
    width: 30px;
    height: 30px;
    &:hover {
      cursor: pointer;
    }
  }
  display: flex;
  align-items: end;
  .title {
    margin-left: 7px;
    font-size: $font-size-lg;
    font-weight: bold;
    line-height: 1.3;
  }
  .icon-list {
    width: 23px;
    height: 23px;
    margin-bottom: 2px;
  }
  .message {
    color: $cardinal;
    font-size: $font-size-sssm;
    margin-right: 5px;
  }
  .search-count {
    font-size: $font-size-ssm;
    margin-left: 10px;
    margin-top: 5px;
  }
}
.table-title-right {
  align-items: flex-end;
  .tc-btn {
    margin: 0 10px;
  }
}
.table-title {
  justify-content: space-between;
  margin: 30px 0 6px;
}

.add-tooltip .ant-tooltip-arrow {
  margin-bottom: 1.5px !important;
}
