@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.csvDownloadModal-content {
  .description-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    label {
      font-size: $font-size-sm;
    }
  }
  .contents-area {
    .all-check-box {
      label {
        font-size: $font-size-ssm;
        font-family: bold;
      }
      margin: 20px 10px 15px;
    }
    .checkbox-group {
      margin: 0 10px;
      .checkbox-line {
        display: flex;
        .check-box {
          label {
            font-size: $font-size-ssm;
          }
          margin-bottom: 15px;
        }
      }
    }
  }
  .button-area {
    display: flex;
    margin: 0 auto;
    justify-content: center;
  }
  .ant-checkbox-wrapper {
    font-size: $font-size-ssm;
  }
  .search-area-wrapper {
    margin: 10px 0;
  }
  .border-line {
    width: 100%;
    height: 1px;
    background-color: lightgray;
    margin: 20px auto;
  }
}
