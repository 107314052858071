@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.line-account-modal {
  .text-area {
    display: flex;
    margin-top: 24px;
    align-items: center;
    position: relative;
    font-size: $font-size-sm;
    .label-required {
      margin-bottom: 2px;
    }
    label {
      align-self: flex-start;
      padding-top: 2px;
      display: inline-block;
      font-size: $font-size-ssm;
      width: 170px;
      min-width: 170px;
    }
    .line-break-anywhere {
      line-break: anywhere;
    }
  }
  .tc-input-preLabel {
    min-width: 170px;
  }
}
