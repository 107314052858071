@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.reference-row {
  display: flex;
  align-items: center;
  margin: 20px 0;
  .font-sm {
    font-size: $font-size-sm;
  }
  .sub-value {
    margin-left: 20px;
  }
  .reference-value {
    white-space: pre-wrap;
    display: flex;
  }
}
