@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.line-richmenu-modal {
  h3 {
    margin-top: 20px;
  }
  h3:last-child {
    background-color: black;
  }

  .text-area {
    display: flex;
    margin-top: 24px;
    align-items: center;
    position: relative;
    font-size: $font-size-sm;
    label {
      align-self: flex-start;
      padding-top: 2px;
      display: inline-block;
      font-size: $font-size-ssm;
      width: 170px;
      min-width: 170px;
    }
  }
  .tc-input-preLabel,
  .tc-select-preLabel,
  .tc-range-picker-preLabel,
  .tc-radio-pre-label {
    min-width: 170px;
  }
  .tc-radio-label {
    font-size: $font-size-ssm;
  }

  .contents-setting-row {
    display: flex;
    .contents-setting-title {
      min-width: 170px;
    }
    .contents-setting-tip {
      display: flex;
      flex-direction: column;
      font-size: $font-size-ssssm;
      flex: 1;
      color: $dark-grey;
      font-weight: normal;
    }
  }

  .check-area {
    display: flex;
    margin-top: 24px;
    position: relative;
    .pre-label {
      font-size: $font-size-ssm;
      min-width: 170px;
    }
    .menubar-container {
      display: flex;
      flex-direction: column;
      position: relative;
      gap: 12px;
      .input-line {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        justify-content: space-between;
        .tc-select-error {
          position: absolute;
          height: auto;
          right: 0px;
          color: $tc-red;
          font-size: $font-size-sssm;
          top: -24px;
          margin-top: 8px;
        }
      }
    }
  }

  .ant-layout {
    background-color: white;
    .ant-layout-sider {
      background-color: white;
    }
  }
  .image-preview-label {
    font-size: $font-size-ssm;
    font-weight: bold;
  }
  .image-area {
    display: flex;
    flex-direction: row;
    .pre-label {
      min-width: 100px;
      font-size: $font-size-ssm;
      &.label-required {
        margin-bottom: 2px;
        @include requiredItem();
        &.action-label {
          margin-top: 20px;
        }
      }
    }
  }
  .richmenu-file-upload-area {
    display: flex;
    flex-direction: column;
    font-size: $font-size-ssssm;
    background-color: $grey;
    width: fit-content;
    position: relative;
    .close-icon {
      position: absolute;
      right: 3px;
      top: 3px;
      font-size: 18px !important;
      z-index: 1;
    }
    .upload-tip {
      user-select: none;
      span {
        display: block;
        text-align: center;
      }
      .upload-format {
        margin-top: 10px;
      }
      .upload-size {
        margin-top: 10px;
      }
    }
  }
}

.line-richmenu-reference-modal {
  .line-richmenu-reference-modal-content {
    padding: 0px 24px;
  }

  h3 {
    margin-top: 20px;
  }

  .cus-pre-label {
    min-width: 200px;
  }
  .reference-value {
    font-size: $font-size-ssm;
  }

  .ant-layout {
    background-color: white;
    .ant-layout-sider {
      background-color: white;
    }
  }
  .image-preview-label {
    font-size: $font-size-ssm;
    font-weight: bold;
  }
  .image-area {
    display: flex;
    flex-direction: row;
    .pre-label {
      min-width: 100px;
      font-size: $font-size-ssm;
      &.action-label {
        margin-top: 20px;
      }
    }
    .cus-pre-label {
      width: 0px;
      min-width: 0px;
    }
  }
  .richmenu-file-upload-area {
    background-color: $grey;
  }
}
