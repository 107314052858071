@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.mount-page-title-note {
  margin: 0 0 5px 20px;
  color: gray;
  font-size: $font-size-ssm;
}
