@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.heatmap-page {
  .search-form {
    .input-container {
      align-items: flex-end;
      margin-bottom: 24px;
    }
    .form-container {
      font-size: $font-size-ssm;
      .tc-input-label {
        @include requiredItem();
      }
      .tc-input-info {
        height: 24px;
        margin-bottom: 5px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        &.flex-end {
          justify-content: flex-end;
        }
      }
      .tc-input-error {
        color: $cardinal;
        font-size: $font-size-sssm;
      }
      .form-line {
        display: flex;
        align-items: center;

        .suffix {
          margin-left: 5px;
          align-self: flex-end;
          height: auto;
        }
        .range {
          margin-left: 5px;
        }
      }
    }
    .slider-area {
      display: flex;
      align-items: center;
      .play-icon {
        width: 30px;
        height: 30px;
        margin-top: 16px;
      }
      .slider {
        margin-top: 22px;
        margin-left: 20px;
        width: 400px;
        .ant-slider-rail {
          border-radius: 10px;
          background-color: #c4c2c3;
          height: 8px;
        }
        .ant-slider-track {
          border-radius: 10px;
          background-color: #9b233f;
          height: 8px;
        }
        .ant-slider-handle {
          height: 20px;
          width: 20px;
          margin-top: -6px;
          background-color: #9b233f;
          border-color: #9b233f;
        }
      }
    }
  }
  .map-area {
    height: 60vh;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 10px;
    .no-data {
      height: 100%;
      box-shadow: 0px 0px 5px $grey;
      text-align: center;
      padding-top: 60px;
      color: $dark-grey;
    }
  }
}
