@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.tc-date-picker-wrapper {
  font-size: $font-size-ssm;
  border-radius: 5px;
  border-color: $tc-gray-d1 !important;
  border-width: 1px;
  .tc-date-picker-preLabel {
    min-width: 130px;
    display: inline-block;
    &.label-required {
      @include requiredItem();
    }
    &.label-required-disabled {
      @include requiredDisabledItem();
    }
    &.label-disabled {
      color: $tc-gray-l1;
    }
  }
  .tc-date-picker-label-area {
    align-items: baseline;
    margin-bottom: 5px;
    min-height: 24px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    &.flex-end {
      justify-content: flex-end;
    }
    .tc-date-picker-label {
      color: $tc-black;
      line-height: 1.5;
      &.label-bold {
        font-weight: bold;
      }
      &.label-disabled {
        color: $tc-gray-l1;
      }
      &.label-required {
        @include requiredItem();
      }
    }
    .tc-date-picker-error {
      color: $tc-red;
      font-size: $font-size-sssm;
    }
  }
  .tc-date-picker-line {
    display: flex;
    align-items: center;
    .prefix {
      margin-right: 10px;
      width: 100px;
      text-align: end;
      &.label-disabled {
        color: $tc-gray-l1;
      }
      &.label-required {
        @include requiredItem();
      }
    }
    .suffix {
      margin-left: 5px;
      &.label-disabled {
        color: $tc-gray-l1;
      }
      &.label-required {
        @include requiredItem();
      }
    }
    .uneditable {
      font-size: $font-size-sm;
    }
  }
  .ant-picker {
    width: 100%;
    background-color: $tc-white;
    border-radius: 5px;
    border: 1px solid $tc-gray-d1 !important;
    .ant-picker-focused {
      transition: box-shadow 0.2s;
      box-shadow: 0 0 0 1px black;
    }
    &.ant-picker-disabled {
      background-color: $tc-gray-l3;
      border: 1px solid transparent !important;
    }
  }

  input {
    font-size: $font-size-sm;
    &::placeholder {
      font-size: $font-size-ssm;
    }
  }
  .picker-icon {
    width: 20px;
    height: 20px;
  }
}
