@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.tc-range-picker-wrapper {
  font-size: $font-size-ssm;
  .tc-range-picker-preLabel {
    min-width: 130px;
    display: inline-block;
    &.label-required {
      @include requiredItem();
    }
    &.label-required-disabled {
      @include requiredDisabledItem();
    }
    &.label-disabled {
      color: $tc-gray-l1;
    }
  }
  .tc-range-picker-label-area {
    align-items: baseline;
    margin-bottom: 5px;
    min-height: 24px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .tc-range-picker-label {
      color: $tc-black;
      line-height: 1.5;
      &.label-bold {
        font-weight: bold;
      }
      &.label-disabled {
        color: $tc-gray-l1;
      }
      &.label-required {
        @include requiredItem();
      }
    }
  }
  .tc-range-picker-line {
    display: flex;
    align-items: center;
    .tc-range-picker-container {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      max-width: 292px;
      .tc-range-picker-error {
        position: absolute;
        top: -24px;
        right: 0;
        z-index: 99;
        margin-top: 8px;
        height: auto;
        color: $tc-red;
        font-size: $font-size-sssm;
        text-align: end;
        vertical-align: bottom;
      }
    }

    .prefix {
      margin-right: 10px;
      &.label-disabled {
        color: $tc-gray-l1;
      }
      &.label-required {
        @include requiredItem();
      }
    }
    .suffix {
      margin-left: 5px;
      &.label-disabled {
        color: $tc-gray-l1;
      }
      &.label-required {
        @include requiredItem();
      }
    }
    .range-text {
      font-size: $font-size-ssm;
      margin-inline: 10px;
      &.label-disabled {
        color: $tc-gray-l1;
      }
    }
    .uneditable {
      font-size: $font-size-sm;
    }
    ::placeholder {
      transform: translateY(1px);
    }
  }
}
