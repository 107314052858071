@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.nf-input-wrapper {
  margin: $input-wrapper-margin;
  display: flex;
  align-items: center;
  position: relative;
  .nf-input-error {
    color: $cardinal;
    position: absolute;
    top: -9px;
    right: 0;
    margin-left: $input-label-width;
    font-size: $font-size-sssm;
  }
  .nf-input {
    padding-left: $input-padding-left;
    &::placeholder {
      padding-left: $input-placeholder-padding-left;
      font-size: $font-size-ssm;
    }
    &.input-block-grey {
      background-color: $grey;
      margin: $input-block-margin;
      flex: 1;
      padding-top: 1px;
      padding-bottom: 1px;
      &::placeholder {
        padding-left: $input-placeholder-padding-left;
        font-size: $font-size-sssm;
      }
    }
    &.input-block-grey-normal {
      background-color: $grey;
      margin: 8px 0px;
      font-size: $font-size-ssm;
      &::placeholder {
        padding-left: $input-placeholder-padding-left;
        font-size: $font-size-ssm;
      }
    }
    &:not(.input-block-grey) {
      width: $input-width;
      height: $input-height;
    }
    &.input-block-grey-short {
      font-size: $font-size-ssm;
      background-color: $grey;
      margin: $input-block-margin;
      flex: 1;
      padding-top: 1px;
      padding-bottom: 1px;
      width: $input-width-short;
      height: $input-height;
      &::placeholder {
        padding-left: $input-placeholder-padding-left;
        font-size: $font-size-ssm;
      }
    }
  }
  .nf-select {
    width: $input-width;
    font-size: $font-size-ssm;
    &.select-block-grey,
    &.select-inline-grey {
      font-size: $font-size-sssm;
      .ant-select-selector {
        background-color: $grey;
        margin: $input-block-margin;
      }
      .ant-select-clear,
      .anticon-close-circle {
        background-color: $grey;
      }
      &.ant-select-multiple .ant-select-selection-item {
        background-color: $white;
      }
    }
    &.select-block-grey-normal {
      font-size: $font-size-ssm;
      .ant-select-selector {
        background-color: $grey;
        margin: 8px 0px;
      }
      .ant-select-clear,
      .anticon-close-circle {
        background-color: $grey;
      }
    }

    &.select-inline-grey {
      width: 175px;
    }
  }
  .nf-input-label {
    display: inline-block;
    font-size: $font-size-ssm;
    width: $input-label-width;
    &.label-width-wider {
      width: $input-label-width-wider;
    }
    &.label-required {
      @include requiredItem();
    }
  }
  .nf-text-after {
    right: -15px;
    position: absolute;
    font-size: $font-size-ssssm;
  }

  &.nf-input-wrapper-small {
    .nf-input-label {
      font-size: $font-size-sssm;
    }
    .ant-select-selection-item {
      font-size: $font-size-sssm;
    }
    font-size: $font-size-sssm;
  }
  &.nf-input-wrapper-title-short {
    // ラベルについて
    font-size: $font-size-ssm;
    .nf-input-label {
      font-size: $font-size-ssm;
    }
    .label-width-wider {
      width: $input-label-width-wider;
    }
    .label-width-short {
      width: $input-label-width-short;
    }
    .ant-select-selection-item {
      font-size: $font-size-ssm;
      width: $input-label-width;
    }
    .input-width-small {
      width: $input-label-width-short;
    }
    width: $input-width-wider;
    font-size: $font-size-ssm;
    margin: 0;
    .anticon-down {
      font-size: 10px;
    }
  }
  &.nf-input-wrapper-short {
    // ラベルについて
    .nf-input-label {
      font-size: $font-size-ssm;
    }
    .label-width-wider {
      width: $input-label-width-wider;
    }
    .label-width-short {
      width: $input-label-width-short;
    }
    .label-width-short-center {
      width: $input-label-width-wide-short;
      align-items: center;
    }
    .ant-select-selector {
      width: $input-label-width-wider;
    }
    .nf-select {
      font-size: $font-size-ssm;
      width: $input-width-wide-select;
    }
    .input-width-small {
      width: $input-label-width-short;
    }
    width: $input-width-wide;
    font-size: $font-size-ssm;
    margin: 0;
    .anticon-down {
      font-size: 10px;
    }
  }
  &.nf-input-wrapper-search-short {
    .nf-input-label {
      font-size: $font-size-ssm;
    }
    .label-width-wider {
      width: $input-label-width;
    }
    .label-width-wide {
      width: $input-width-200;
    }
    .label-width-short {
      width: $input-label-width-short;
    }
    .nf-select {
      font-size: $font-size-ssm;
      width: $input-width-wide-select;
    }
    .input-width-small {
      width: $input-label-width-short;
    }
    width: $input-width-wide-area;
    font-size: $font-size-ssm;
    margin: 0;
    .anticon-down {
      font-size: 10px;
    }
  }
  &.nf-input-wrapper-edit-small {
    .nf-input-label {
      font-size: $font-size-ssm;
    }
    .ant-select-selection-item {
      font-size: $font-size-ssm;
    }
    width: $input-label-width;
    font-size: $font-size-ssm;
    margin: 0;
    .anticon-down {
      font-size: 10px;
    }
  }
  &.nf-input-wrapper-half {
    .nf-input-label {
      font-size: $font-size-ssm;
      // width: $input-label-width-half;
    }
    .label-width-short {
      width: $input-label-width-short;
    }
    .label-width-wider {
      width: $input-label-width-wider;
    }
    .ant-select-selection-item {
      font-size: $font-size-sssm;
      // width: $input-label-width-half;
    }
    .input-block-grey {
      font-size: $font-size-ssm;
      background-color: $grey;
      margin: $input-block-margin;
      flex: 1;
      padding-top: 1px;
      padding-bottom: 1px;
      width: $input-width-wide;
      height: $input-height;
      &::placeholder {
        padding-left: $input-placeholder-padding-left;
        font-size: $font-size-ssm;
      }
    }

    width: $input-label-width-half;
    font-size: $font-size-ssm;
    margin: 0;
    .anticon-down {
      font-size: 10px;
    }
  }
  &.nf-input-wrapper-wide {
    font-size: $font-size-ssm;
    .nf-input-label {
      font-size: $font-size-ssm;
    }
    .ant-select-selection-item {
      font-size: $font-size-ssm;
    }
    .input-block-grey {
      font-size: $font-size-ssm;
      background-color: $grey;
      margin: $input-block-margin;
      flex: 1;
      padding-top: 1px;
      padding-bottom: 1px;
      width: $input-width-wider;
      height: $input-height;
      &::placeholder {
        padding-left: $input-placeholder-padding-left;
        font-size: $font-size-ssm;
      }
    }

    width: $input-label-width-wide;
    font-size: $font-size-ssm;
    margin: 0;
    .anticon-down {
      font-size: 10px;
    }
  }
}
.nf-wrapper-uneditable {
  .input-value {
    font-size: $font-size-ssm;
  }
}
