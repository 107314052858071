@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.category-modal {
  .tc-input-preLabel {
    min-width: 180px;
  }
  .pre-label {
    min-width: 180px !important;
    display: inline-block;
  }
  .check-area {
    margin: 25px 0 10px 0;
    align-items: center;
    display: flex;
  }
  .font-sm {
    font-size: $font-size-sm;
  }
  .tc-select-preLabel {
    min-width: 180px;
  }
}
