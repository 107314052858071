@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.tc-table-header {
  display: flex;
  align-items: center;
  .sort-icon {
    width: 18px;
    height: 18px;
  }
}

.sort-span {
  margin-left: 5px;
  display: flex;
  &:hover {
    cursor: pointer;
  }
}
