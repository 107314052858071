@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.tc-file-view {
  height: 250px;
  width: 95%;
  border-radius: 5px;
  margin: 10px 12px 15px;
  align-items: center;
  background-color: $grey;
  overflow-x: auto;
  overflow-y: auto;
  .csv-layout {
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
    overflow-x: auto;
    overflow-y: auto;
    display: flex;
    &.result-placeholder {
      height: 100%;
    }
    .upload-placeholer {
      font-size: $font-size-ssm;
      color: $light-grey;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }

  font-family: $font-family;
  table {
    border-width: 1px 0px;
  }
  table th {
    padding: 3px 10px;
    background-color: $dark-grey;
    line-height: 30px;
    white-space: nowrap;
    font-weight: normal;
    color: $white;
    font-size: $font-size-ssm;
    text-align: center;
    min-width: 150px;
  }
  table td {
    white-space: nowrap;
    font-size: $font-size-ssm;
    text-align: center;
    line-height: 45px;
    background-color: $white;
    border-width: 1px 0px;
  }
}
.tc-upload {
  display: flex;
  align-items: center;
  margin-top: 10px;
  .upload-info {
    font-size: $font-size-ssm;
    .count-info {
      margin-left: 15px;
    }
  }
  .format-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
.csv-template-download-link {
  width: auto;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: #837F80;
  font-size: 12px;
  border-bottom: 1px solid rgba(131, 127, 128, 0.5);
  cursor: pointer;
}
