@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.number-picker-after {
  margin-left: 3px;
}
.number-picker {
  .ant-select-selector {
    .ant-select-selection-search {
      font-size: $font-size-sm;
    }
  }
}
