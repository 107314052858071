@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.map-wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
}

/* .map {
  border-radius: 6px;
} */
