@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.tc-checkbox {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.tc-checkbox-label {
  position: relative;
  display: flex;
  align-items: flex-end;
}

.tc-radio-pre-label {
  &.label-required {
    margin-bottom: 2px;
  }
  &.label-disabled {
    color: $tc-gray-l1;
  }
}

.check-icon {
  position: absolute;
  left: 1px;
  width: 15px;
  height: 15px;
}
.read-only-label {
  font-size: $font-size-sm;
  margin-right: 30px;
  margin-bottom: 15px;
}
.tc-checkbox-label {
  font-size: $font-size-sm;
  cursor: pointer;
  user-select: none;
  height: 16px;
  line-height: 16px;
  &::before {
    content: '\a0';
    display: inline-block;
    border: 1px solid silver;
    margin-right: 6px;
    text-align: center;
    line-height: 16px;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    font-weight: bold;
    background-color: $white;
  }
}

.tc-radio-label {
  display: flex;
  font-size: $font-size-sm;
  cursor: pointer;
  user-select: none;
  height: 16px;
  line-height: 16px;
  input {
    display: none;
  }
}

.tc-checkbox-disabled {
  .tc-checkbox-label {
    cursor: not-allowed;
    color: $light-grey;
  }
  .tc-radio-label {
    cursor: not-allowed;
    color: $light-grey;
  }
}

.checkbox-list {
  display: flex;
  width: 500px;
  justify-content: space-between;
}

.tc-menu-area {
  .tc-checkbox-wrapper {
    margin-right: 30px;
    margin-bottom: 23px;
    .check-icon {
      top: 0px;
    }
  }
  .menu-line {
    max-width: 700px;
  }
  .sub-menu {
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px;
    .wrap-option {
      &:nth-child(1) {
        width: 140px;
      }
      &:nth-child(2) {
        width: 140px;
      }
      &:nth-child(3) {
        width: 250px;
      }
    }
  }
}
.circle {
  margin-right: 6px;
}
