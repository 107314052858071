@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.cus-input-line.input-images {
  align-items: flex-start;
  margin-top: 30px;
  margin-bottom: 320px;
  .images-container {
    width: 770px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    .delete-icon {
      position: absolute;
      top: 3px;
      z-index: 50;
      right: 5px;
      width: 17px;
      height: 17px;
    }
  }
  .image-cell {
    position: relative;
    height: 150px;
    width: 19%;
    margin-right: 2px;
    background-color: $grey;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 40px;
    border-radius: 2px;
    //overflow: hidden;
    .tc-btn {
      border-width: 2px;
      margin: 0;
      margin-bottom: 17px;
      font-size: $font-size-ssm;
      width: 130px;
    }
    .image-label {
      position: absolute;
      bottom: -25px;
      font-size: $font-size-ssm;
    }
  }
}
