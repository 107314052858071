@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.tc-btn {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  margin: $btn-margin-vertical $btn-margin-horizontal;
  padding: $btn-padding;
  border-radius: $btn-border-radius;
  font-size: $font-size-ssm;
  width: $btn-width;
  height: $btn-height;
  line-height: 30px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  .btn-icon {
    width: 20px;
    height: 20px;
  }
  .ad-pad {
    padding-top: 2px;
  }
  &:hover {
    opacity: 0.5;
  }
  &:active {
    opacity: $btn-active-opacity;
  }
  &:disabled {
    &:hover {
      cursor: not-allowed;
      opacity: 1;
    }
  }
}

.tc-btn .tc-btn-link {
  position: absolute;
  right: 0;
  bottom: -10px;
  text-decoration: underline;
  font-size: $font-size-sssm;
  width: 140px;
  margin: $btn-margin-vertical 0;
}

.tc-btn-small {
  font-size: $font-size-sssm;
}

.tc-btn-table {
  width: $btn-table-width;
  height: $btn-table-height;
  font-size: $font-size-ssm;
  border: $btn-wider-border;
  margin: $btn-wider-margin;
}

.tc-btn-wider {
  width: $btn-wider-width;
  border: $btn-wider-border;
  margin: $btn-wider-margin;
}

.tc-btn-black {
  background-color: $paging-active-black;
  color: $white;
}

.tc-btn-disabled:not(.tc-btn-link) {
  background-color: $light-grey !important;
  border-color: $light-grey !important;
  border-radius: 3px;
  opacity: 0.7;
}

.tc-btn-light-red:not(.tc-btn-link) {
  background-color: $shallow-red !important;
  border-color: $shallow-red !important;
  color: $white;
}

.tc-btn-white {
  background-color: $white;
  color: $paging-active-black;
  border: $btn-wider-border;
}

.tc-btn-red {
  background-color: $dark-red;
  border-color: $dark-red;
  color: $white;
}

.tc-btn-black.tc-btn-wider {
  border-color: $paging-active-black !important;
}

.tc-btn-large {
  width: 40px;
  height: 40px;
}
