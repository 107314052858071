@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.modal-title {
  .title-text {
    font-size: $font-size-lm;
    font-weight: bold;
    margin-right: 5px;
  }
  .title-tip {
    color: $dark-red;
    font-size: $font-size-ssm;
  }
  border-bottom: 1px solid $grey;
  display: flex;
  justify-content: space-between;
}
