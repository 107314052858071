@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.header {
  display: flex;
  height: 60px;
  width: 100vw;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 1px 0px $grey;
  padding-left: 30px;
  padding-right: 0;
  position: fixed;
  background-color: white;
  z-index: 501;
  .header-left {
    display: flex;
    align-items: center;
    .header-logo {
      margin-left: 50px;
      width: 140px;
      height: 100px;
      margin-top: -40px;
      position: relative;
    }
    .logo-image {
      font-size: 140px;
      cursor: default;
      pointer-events: none;
    }
  }
  .header-right {
    margin-right: 90px;
    display: flex;
    align-items: center;
    font-size: $font-size-ssm;
    .header-personal-info {
      margin-left: 20px;
    }
  }
}
.changePassword-modal {
  .ant-modal-body {
    @include flex-column-center();
    padding: 50px 100px;
  }
  .input-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 48px;
    .tc-btn {
      margin: 0;
    }
  }
}

.header-personal-popover {
  display: block;
  justify-content: center;
  table {
    border-collapse: separate;
    border-spacing: 20px 2px;
    text-align: left;
    vertical-align: top;
    td {
      vertical-align: top;
    }
  }
  .account-detail {
    .line {
      display: flex;
      margin-top: 10px;
      margin-bottom: 10px;
      div {
        text-align: left;
      }
    }
    .border {
      height: 1px;
      width: 100%;
      background: $grey;
    }
  }
  .header-logoutButton {
    text-align: right;
    text-decoration: underline;
    color: $silver;
    cursor: pointer;
  }
}
