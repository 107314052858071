@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.template-modal-area {

  .code-area, .reference-area {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.75rem;;
    color: #393939;
    line-height: 1.5;
    margin-top: 30px;

    .pre-label {
      min-width: 150px;
    }
  }

  .reference-area {
    .reference-template-text {
      width: calc(100% - 150px);
      overflow-wrap: break-word;
    }
  }

  &-input {
    width: 60%;
    margin-top: 30px;
    position: relative;

    .tc-select-wrapper, .tc-input-wrapper {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      align-items: center;

      .tc-select-label-area, .tc-input-label-area {
        min-width: 150px;

        .tc-select-error, .tc-input-error {
          position: absolute;
          top: -20px;
          right: 0;
        }
      }

      .tc-select-line, .tc-input-line {
        width: calc(100% - 150px);
        flex-shrink: 0;
      }
    }
  }

  &-text {
    width: 100%;
    position: relative;

    .input-textarea {
      label::after {
        content: "*";
        color: #9B233F;
      }
      label {
        min-width: 150px;
      }
      .tc-textarea-error {
        color: #9b233f;
        font-size: 0.625rem;
        line-height: 1.5715;
        position: absolute;
        top: -20px;
        right: 0;
      }
    }
  }
}