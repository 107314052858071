@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.stamp-modal {
  .cus-input-line {
    margin-bottom: 0px;
    .font-sm {
      font-size: $font-size-sm;
    }
    .space-left {
      margin-left: 10px;
    }
  }
  .pre-label {
    width: 130px;
    font-size: $font-size-ssm;
    display: inline-block;
    &.label-required {
      @include requiredItem();
    }
  }
  .input-area {
    .label-required {
      margin-bottom: 2px;
    }
  }
  .input-stamp-count {
    .suffix {
      position: relative;
      top: 10px;
    }
  }
  .input-stamp-count {
    .suffix {
      left: 5px;
    }
  }
  .image-area {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    .image-border {
      border-radius: 2px;
      overflow: hidden;
    }
    .image-select {
      position: relative;
      height: 150px;
      width: 150px;
      background-color: $grey;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      border-radius: 2px;
      overflow: hidden;
      .tc-btn {
        width: 118px;
        border-width: 2px;
        margin: 0;
        margin-bottom: 17px;
        font-size: $font-size-ssm;
      }
      .close-icon {
        position: absolute;
        right: 3px;
        top: 3px;
        font-size: 18px !important;
        z-index: 1;
      }
    }
    .image-box-area-label {
      width: 130px;
      flex-shrink: 0;
    }
    .uploadImageBox {
      position: relative;
      display: inline-block;
      background-color: $grey;
      width: 180px;
      height: 200px;
      padding-bottom: 3rem;
      border-radius: 2px;
      overflow: hidden;
      &.empty {
        background-color: white;
      }
      .close-icon {
        position: absolute;
        right: 3px;
        top: 3px;
        font-size: 18px !important;
        z-index: 1;
      }
    }
    .uploadImageRow {
      display: flex;
      justify-content: space-between;
    }
    .imageText {
      text-align: center;
    }
  }
  .detail-area-title {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    border-top: solid 1px #efe6e5;
    border-bottom: solid 1px #efe6e5;
    cursor: pointer;
  }
  .text-area {
    display: flex;
    margin-top: 20px;
    margin-right: 30px;
    align-items: center;
    position: relative;
    label {
      align-self: flex-start;
      padding-top: 5px;
      display: inline-block;
      font-size: $font-size-ssm;
      width: 130px;
    }
  }
  .date-area {
    display: flex;
    margin-top: 20px;
    align-items: center;
    position: relative;
  }
  .valid-days-area {
    max-height: 80px;
  }
  .check-area {
    display: flex;
    margin-top: 24px;
    position: relative;
    .expire-container {
      display: flex;
      flex-direction: column;
      position: relative;
      gap: 24px;
      .input-line {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        justify-content: space-between;
        .tc-select-error {
          position: absolute;
          height: auto;
          right: 0px;
          color: $tc-red;
          font-size: $font-size-sssm;
          top: -24px;
          margin-top: 8px;
        }
      }
    }
  }
  .file-area {
    display: flex;
    align-items: center;
    position: relative;
    label {
      font-size: $font-size-ssm;
    }
  }
  .reference-area {
    display: flex;
    font-size: $font-size-ssm;
    margin: 22px 0;
  }
  .input-fixed-phrase {
    margin-top: 0;
  }
  .reference-image-area {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
    label {
      width: 130px;
      font-size: 12px;
    }
    .referenceImageText {
      font-size: 12px;
    }
  }
}
