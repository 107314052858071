@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.gacha-shop_gift_list-area {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 32px;

  .gacha-shop_gift_list-card {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 6px;
    border: 1px solid silver;
    padding: 16px 32px;

    .gacha-shop_gift_list-header-area {
      width: 100%;
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: center;

      .gacha-shop_gift_list-header {
        width: calc(100% - 60px);
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        align-items: center;
        column-gap: 10px;
      }

      .gacha-shop_gift_list-title-area {
        width: 100%;
        .tc-input-wrapper {
          .tc-input-label-area {
            display: none;
          }
        }
        .gacha-shop_gift_list-title {
          font-weight: bold;
        }
        .gacha-shop_gift_list-target-shop {
          color: #837f80;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .group_name-disabled {
          color: #bdbdbd;
          .gacha-shop_gift_list-target-shop {
            color: #bdbdbd;
          }
        }
      }

      .gacha-shop_gift_list-delete-btn-area {
        flex-shrink: 0;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
    .gacha-shop_gift_list-contents-area {
      width: 100%;
      padding-top: 32px;
      .check-area {
        width: 100%;
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        align-items: center;

        .cus-pre-label::after {
          content: '*';
          color: #990000;
          font-size: 0.875rem;
          padding-top: 35px;
        }
      }

      .map-element_area {
        .map-element_select-field-area {
          .map-element_select-field {
            .dualListBox-container {
              .tc-dualListBox {
                .select-container {
                  width: 277px;
                  height: 185px;
                }
              }
            }
            .map-element_select-field-label {
              width: 108px;
            }
          }
          .tc-btn-black {
            width: 277px;
            margin-left: 102px;
          }
        }
      }
      .input-gift {
        .gacha-shop_gift_list-gift-field {
          flex-shrink: 0;
          width: 108px;
          font-size: 0.75rem;
        }
        .gacha-shop_gift_list-gift-field::after {
          font-size: 0.875rem;
          content: '*';
          color: #9b233f;
        }
        .gift-field-disabled {
          color: #bdbdbd;
        }
        .limit-container {
          .tc-input-wrapper {
            .tc-input-label-area {
              padding-right: 24px;
            }
          }
          .tc-select-wrapper {
            .tc-select-label-area {
              padding-right: 24px;
            }
          }
        }
        .gift-input-area,
        .extra-input {
          .tc-input-wrapper {
            .tc-input-label-area {
              padding-right: 34px;
            }
          }
        }
      }
    }
    .contents-area-no-padding {
      padding-top: 0;
    }
  }

  .list-card-no-padding {
    padding-top: 0;
  }

  .gacha-shop_gift_list-add-card-btn {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .add-btn-area {
    padding-top: 5px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
.gacha-shop_gift_list-area-disabled {
  cursor: not-allowed;
}
