@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.coupon-userResult-csv-modal {
  &-checkbox {
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
