@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.name-modal {
  &-input-area {
    .tc-select-wrapper,
    .tc-input-wrapper {
      display: flex;
      position: relative;
      padding-top: 24px;
      .tc-select-label-area,
      .tc-input-label-area {
        width: 130px;
        flex-shrink: 0;
        .tc-select-error,
        .tc-input-error {
          position: absolute;
          right: 0;
          top: 8px;
        }
      }
      .tc-select-line,
      .tc-input-line {
        width: 100%;
      }
      .flex-end {
        display: none;
      }
    }
    .text-area {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 24px;
      label {
        width: 130px;
        flex-shrink: 0;
        font-size: 0.75rem;
      }
      span {
        width: 100%;
        font-size: 0.875rem;
        overflow-wrap: break-word;
      }
    }
  }
}