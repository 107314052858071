@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.tc-tooltip {
  margin-left: 5px;
}

.icon-help {
  width: 15px;
  height: 15px;
  position: relative;
  top: 2px;
}
