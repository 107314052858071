@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.tc-range-picker {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  .pre-label {
    width: 70px !important;
    margin-left: 35px;
    margin-right: 5px;
  }
}
.date-picker-wrapper {
  position: relative;
  input {
    font-size: $font-size-sm;
  }
  .font-sm {
    font-size: $font-size-sm;
  }
}

.picker-icon {
  width: 20px;
  height: 20px;
}
.range-text {
  font-size: $font-size-base;
  margin: 0 12px;
}
