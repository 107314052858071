$red: #ff0000;
$dark-red: #990000;
$shallow-red: #d89f9f;
$cardinal: #990000;
$yellow: #eaab01;
$grey: #f5f5f5;
$dark-grey: #808080;
$shallow-grey: #f0f0f0;
$light-grey: #bdbdbd;
$silver: #7b7b7b;
$black: #000000;
$light-black: #242424;
$transparent-black: #2424241a;
$white: #ffffff;
$smoke-white: #f5f5f5;
$table-header-black: #837f80;
$paging-active-black: #393939;

// 色を修正
$tc-black: #393939;
$tc-white: #ffffff;
$tc-gray-d1: #707070;
$tc-gray: #837f80;
$tc-gray-l1: #c4c2c3;
$tc-gray-l2: #e3e3e3;
$tc-gray-l3: #f5f5f5;
$tc-gray-l4: #fdfcfc;
$tc-red: #9b233f;
$tc-pink-d1: #c4a6a9;
$tc-pink: #f16ba6;
$tc-pink-l1: #ffa7e2;
$tc-blue-d1: #4a60a1;
$tc-blue: #14789c;
$tc-blue-l1: #8b9ee3;
$tc-green: #149c75;
$tc-purple: #c9c1d7;

$font-size-base: 1rem;
$font-size-xxxl: $font-size-base * 2;
$font-size-xxl: $font-size-base * 1.625;
$font-size-xl: $font-size-base * 1.5;
$font-size-lg: $font-size-base * 1.25;
$font-size-lm: $font-size-base * 1.175;
$font-size-sm: $font-size-base * 0.875;
$font-size-ssm: $font-size-base * 0.75;
$font-size-sssm: $font-size-base * 0.625;
$font-size-ssssm: $font-size-base * 0.5;

$font-family: 'Lato', 'Noto Sans JP', 'ヒラギノ角ゴ ProN',
  'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック',
  'MS PGothic', sans-serif;

$text-align-map: (
  text-align-left: left,
  text-align-center: center,
  text-align-right: right,
);

$input-width: 280px;
$input-width-short: 80px;
$input-width-wide-select: 120px;
$input-width-wide: 160px;
$input-width-wide-area: 200px;
$input-width-wider: 240px;
$input-width-50: 50px;
$input-width-100: 100px;
$input-width-125: 125px;
$input-width-150: 150px;
$input-width-200: 200px;
$input-width-250: 250px;
$input-width-300: 300px;
$input-width-350: 350px;
$input-height: 32px;
$input-padding-left: 11px;
$input-placeholder-padding-left: 0px;
$input-label-width-short: 20px;
$input-label-width-wide-short: 40px;
$input-label-width: 80px;
$input-label-width-wider: 120px;
$input-label-width-half: 400px;
$input-label-width-wide: 500px;
$inout-label-font-size: $font-size-sm;
$input-wrapper-margin: 5px 0;
$input-block-margin: 5px 0;

$btn-width: 160px;
$btn-wider-width: 180px;
$btn-table-width: 45px;
$btn-height: 30px;
$btn-table-height: 30px;
$btn-margin-vertical: 24px;
$btn-margin-horizontal: 10px;
$btn-padding: 3px;
$btn-border-radius: 3px;
$btn-active-opacity: 0.5;
$btn-wider-border: 2px solid;
$btn-wider-margin: 0;

$modal-border-radius: 7px;

$upload-picture-card-width: 150px;
$upload-picture-card-height: 200px;
