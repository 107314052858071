@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.gacha-modal {
  .tc-modal-content {
    .input-area {
      .gacha-image-display-area {
        width: 290px;
        position: relative;
        .image-area-error {
          position: absolute;
          right: 0;
          top: -20px;
          color: #9b233f;
          font-size: 0.625rem;
        }
      }
    }
  }
  .gacha-continuous-startup-area {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    .gacha-label-area {
      width: 140px;
      height: 32px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      .gacha-label {
        line-height: 32px;
        font-size: 12px;
      }

      .gacha-label::after {
        content: '*';
        color: #990000;
        font-size: 0.875rem;
        padding-top: 35px;
      }

      #icon_help-circle {
        margin-top: 6px;
      }

      .tc-select-wrapper {
        width: 150px;
      }
    }
    .tc-select-wrapper {
      .tc-select-label-area {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        padding-right: 24px;
      }
    }
  }
  .label-disabled {
    // @include requiredItem();
    color: $light-grey;
  }
  .map-wrapper {
    margin-left: 0 !important;
  }
  .ant-table-sticky-holder {
    position: inherit;
  }
  .plus-icon {
    width: 30px;
    height: 30px;
  }
  .plus-tooltip {
    position: absolute;
    right: 45px;
    margin-top: 10px;
  }
  .ant-input-affix-wrapper {
    background-color: white;
  }
  .ant-table-cell {
    .tc-input-wrapper {
      margin-bottom: 24px;
    }
  }
  .font-sm {
    font-size: $font-size-sm;
  }
  .tc-input-preLabel,
  .tc-range-picker-preLabel,
  .tc-select-preLabel {
    min-width: 140px !important;
  }
  .input-textarea {
    label {
      width: 140px;
    }
  }
  .pre-label {
    min-width: 140px !important;
    font-size: $font-size-ssm;
    display: inline-block;
    &.label-required {
      @include requiredItem();
    }
  }
  .cus-pre-label {
    min-width: 140px !important;
  }
  .show-area {
    margin-top: 30px;
    align-items: center;
    .space-left-small {
      margin-left: 5px;
    }
    &.type {
      margin-top: 16px;
    }
  }
  .space-left {
    margin-left: 10px;
  }
  .image-area {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    .pre-label {
      margin-top: 5px;
    }
    .image-select {
      position: relative;
      height: 150px;
      width: 150px;
      background-color: $grey;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      .tc-btn {
        width: 118px;
        border-width: 2px;
        margin: 0;
        margin-bottom: 17px;
        font-size: $font-size-ssm;
      }
      .close-icon {
        position: absolute;
        right: 3px;
        top: 3px;
        font-size: 18px !important;
        z-index: 1;
      }
    }
  }
  .gacha-content {
    margin-bottom: 45px;
    display: flex;
    flex-direction: column;
    .input-textarea {
      margin-top: 0px;
    }
    .error {
      height: 20px;
      align-items: center;
      margin-bottom: 5px;
      color: #990000;
      font-size: $font-size-sssm;
      display: flex;
      justify-content: flex-end;
    }
    .label-required {
      @include requiredItem();
    }
  }
  .input-fixed-phrase {
    margin-top: 0;
  }
  .date-area {
    display: flex;
    margin-top: 30px;
    align-items: center;
    .date-area-error {
      font-size: $font-size-sssm;
      position: absolute;
      top: -20px;
      color: $cardinal;
      &.from {
        left: 260px;
      }
      &.to {
        left: 550px;
      }
    }
  }
  .push-area {
    margin-top: 30px;
    display: flex;
    align-items: start;
    margin-bottom: 0;
    .push-list {
      display: flex;
      flex-direction: column;
      margin-top: 1px;
      .radio-area {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
      }
      .end {
        margin-bottom: 1px;
      }
    }
    .edit {
      margin-top: 3px;
    }
  }
  .gacha-type-area {
    display: flex;
    align-items: center;
    margin-top: 18px;
  }
  .active-stamp-area {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 12px;
    margin-top: 16px;
    margin-bottom: 30px;
    .label {
      width: 140px;
    }
    .item-area .item:nth-child(2) {
      margin-top: 15px;
    }
  }
  .active-stamp-area-disabled {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 12px;
    color: #c4c2c3;
    margin-top: 16px;
    margin-bottom: 30px;
    .label {
      width: 140px;
    }
    .item-area .item:nth-child(2) {
      margin-top: 15px;
    }
  }
}

.input-gift {
  margin-top: 30px;
  display: flex;
  align-items: start;
  margin-bottom: 0;
  .gift-list {
    display: flex;
    flex-direction: column;
    margin-top: 3px;
    width: 100%;
    .end {
      margin-bottom: 14px;
    }
    .gift-input-area {
      display: flex;
      align-items: center;
      .gift-radio-area {
        font-size: $font-size-ssm;
        min-width: 100px;
        margin-top: 29px;
      }
      &.coupon {
        margin-top: 20px;
      }
    }
    .limit-container {
      .tc-select-preLabel,
      .tc-input-preLabel {
        min-width: 86px !important;
      }
      .suffix {
        margin-inline: 0px;
        margin-left: 10px;
      }
    }
    .input-top-margin {
      margin-top: 0;
    }
    .pre-label {
      min-width: 110px !important;
    }
    .lose {
      display: flex;
      align-items: center;
      margin-top: 20px;
      width: 225px;
      font-size: $font-size-ssm;
      .post-label {
        margin-left: 7px;
      }
      .input-line {
        display: flex;
        align-items: center;
        line-height: 22px;
        &.disabled-theme {
          .ant-input[disabled] {
            background-color: $white;
            border: 1px solid #c4c2c3 !important;
          }
        }
      }
      input {
        background-color: $grey;
        &::placeholder {
          padding-left: $input-placeholder-padding-left;
          font-size: $font-size-ssm;
        }
      }
    }
    .not-end {
      margin-bottom: 30px;
    }
    .bold {
      font-weight: bold;
    }
  }
}

.gacha-reference-modal {
  .font-sm {
    font-size: $font-size-sm;
  }
  .pre-label {
    min-width: 130px;
    font-size: $font-size-ssm;
    display: inline-block;
    &.label-required {
      @include requiredItem();
    }
  }
  .reference-area {
    margin-top: 30px;
    align-items: center;
    display: flex;
    .space-left-small {
      margin-left: 5px;
    }
    .space {
      margin-inline: 10px;
    }
    &.type {
      margin-top: 16px;
    }
  }
  .reference-image-area {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
    label {
      width: 130px;
      font-size: 12px;
    }
    .referenceImageText {
      font-size: 12px;
    }
  }
  .space-left {
    margin-left: 10px;
  }
  .image-area {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    .pre-label {
      margin-top: 5px;
    }
    .image-select {
      position: relative;
      height: 150px;
      width: 150px;
      background-color: $grey;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
    }
    .images-container {
      width: 770px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
    }
    .image-cell {
      position: relative;
      height: 150px;
      width: 19%;
      margin-right: 2px;
      background-color: $grey;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 40px;
      .image-label {
        position: absolute;
        bottom: -25px;
        font-size: $font-size-ssm;
      }
    }
  }
  .push-area {
    margin-top: 30px;
    display: flex;
    .push-label {
      margin-top: 2px;
    }
    .push-list {
      display: flex;
      flex-direction: column;
      .radio-area {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
      }
      .end {
        margin-bottom: 14px;
      }
    }
  }
  .gift-area {
    margin-top: 30px;
    display: flex;
    .gift-label {
      margin-top: 2px;
    }
    .gift-list {
      display: flex;
      flex-direction: column;
      width: 100%;
      .end {
        margin-bottom: 14px;
      }
      .gift-input-area {
        display: flex;
        align-items: center;
        margin-top: 30px;
        .space-left-small {
          margin-left: 5px;
        }
      }
      .pre-label {
        min-width: 110px !important;
      }
      .not-end {
        margin-bottom: 30px;
      }
      .bold {
        font-weight: bold;
      }
    }
  }
  .active-stamp-area {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 12px;
    margin-top: 30px;
    margin-bottom: 30px;
    .label {
      width: 130px;
    }
    .item-area .item:nth-child(2) {
      margin-top: 15px;
    }
  }
}

.gacha-result-modal {
  .font-sm {
    font-size: $font-size-sm;
  }
  .pre-label {
    min-width: 110px;
    font-size: $font-size-ssm;
    display: inline-block;
  }

  table {
    td {
      padding-block: 12px;
    }
    margin-top: 12px;
    tr {
      padding-top: 30px;
    }
    td.space-left {
      padding-left: 20px;
    }
  }
  .result-area {
    margin-top: 30px;
    align-items: center;
    display: flex;
    .space-left-small {
      margin-left: 5px;
    }
  }
}

.trigger-box {
  display: flex;
  align-items: center;
  height: 44px;
  .tc-select-wrapper {
    margin: 0;
    padding: 0;
  }
  .tc-select-label-area {
    display: none;
  }
}
.extra-input {
  .tc-input-preLabel {
    width: 86px !important;
    min-width: 86px !important;
  }
}

.gacha-result-modal {
  .applicant_count-field-area {
    padding-top: 32px;
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: flex-start;
    .applicant_count-field-label {
      width: 200px;
      height: 24px;
    }
    .applicant_count-field-count {
      width: 100%;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
  }
  .location_gift_list-area {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 32px;
    row-gap: 20px;
    .location_gift_list-group_name {
      width: 100%;
      font-weight: bold;
    }
    .location_name-field-area {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      align-items: flex-start;
      .location_name-field-label {
        width: 200px;
        height: 24px;
        flex-shrink: 0;
      }
      .location_name-field-name {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: flex-start;
        column-gap: 20px;
        .location_name-text {
          height: 20px;
        }
      }
    }
    .rank_list-area {
      width: 100%;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      row-gap: 20px;

      .rank_list-raw-area {
        width: 100%;
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        align-items: center;
        column-gap: 20px;

        // 最初の div のみに適用
        > div:first-child {
          width: 180px;
        }
      }

    }
  }
}
.gacha-shop_gift_list-reference-card-area {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 32px;

  .gacha-shop_gift_list-reference-card {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 6px;
    border: 1px solid silver;
    padding: 16px 32px;

    .gacha-shop_gift_list-header {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      .gacha-shop_gift_list-header-label {
        width: 98px;
        height: 19px;
        flex-shrink: 0;
        font-size: 12px;
      }
      .gacha-shop_gift_list-header-shops {
        width: 100%;
        overflow-wrap: break-word;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.85);
      }
    }
    .gift-area {
      width: 100%;
      .gacha-pre-label {
        flex-shrink: 0;
        width: 98px;
        font-size: 12px;
      }
    }
  }
}