@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.global-modal {
  .ant-modal-body {
    @include flex-column-center();
  }
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .global-modal-title {
    font-weight: bold;
    font-size: $font-size-lm;
    margin-bottom: 15px;
    text-align: center;
  }
  .modal-message {
    display: flex;
    font-weight: bold;
    font-size: $font-size-ssm;
    flex-direction: column;
    text-align: center;
    margin-bottom: 10px;
    &.delete-message {
      font-weight: normal;
    }
  }
  .tip {
    color: $dark-red;
    margin-top: 10px;
    font-size: $font-size-ssm;
  }
}
