@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.coordinate-search-area {
  margin: 40px 15px 0;
  .search-title {
    font-size: $font-size-base;
    font-weight: bold;
  }
  .coordinate-search-content {
    margin-top: 10px;
    margin-bottom: 30px;
    display: flex;
    .search-condition {
      display: flex;
      flex-direction: column;
      width: 400px;
      .ant-select-selection-placeholder {
        padding-left: 5px;
      }
      .ant-select-selection-overflow {
        height: 22px;
      }
    }
    .search-button-container {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
    .search-result {
      &:hover {
        cursor: pointer;
      }
      @include shadow-box();
      flex: 1;
      padding: 30px 30px 20px;
      margin-left: 40px;
      margin-bottom: 30px;
      height: 300px;
      overflow-y: auto;
      .result-title {
        font-weight: bold;
        margin-bottom: 20px;
      }
      .result-box {
        div {
          font-size: $font-size-ssm;
        }
        display: flex;
        @include shadow-box();
        background-color: $white;
        margin: 10px 0;
        padding: 10px;
        &.result-box-selected {
          background-color: $grey;
        }
      }
      .tag {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      .item-box {
        margin-left: 20px;
      }
    }
  }
}
