@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.advent-calendar {
  &-result-modal {
    .input-area {
      margin-top: 24px;
      .reference-row {
        margin: 0 0 10px 0;
      }
      .label-area {
        width: 100%;
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        align-items: center;
        margin: 24px 0 12px 0;
        span {
          font-size: 0.75rem;
          width: 130px;
        }
      }
    }
  }
  &-reference-modal {
    .text-area {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      .pre-label {
        font-size: 0.75rem;
      }
    }
    .input-area {
      display: flex;
      flex-flow: column;
      column-gap: 30px;
      padding-top: 30px;
      .display-text-area {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        span {
          font-size: 0.75rem;
        }
        .pre-label {
          width: 150px;
          line-height: 24px;
          font-size: 0.75rem;
          flex-shrink: 0;
        }
        .pre-text {
          font-size: 14px;
          word-wrap: break-word;
          line-height: 24px;
          padding-top: 1px;
        }
        .pre-template-text {
          width: calc(100% - 150px);
        }
      }
      .long-label-area {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        div {
          height: 32px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          span {
            font-size: 0.75rem;
          }
        }
        .pre-label {
          width: 150px;
          line-height: 16px;
        }
      }

      .display-img-area {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        .pre-label {
          width: 150px;
          line-height: 24px;
          font-size: 0.75rem;
        }
      }
      .display-date {
        &-area {
          display: flex;
          flex-flow: row;
          justify-content: flex-start;
          align-items: flex-start;

          .pre-label {
            width: 150px;
            line-height: 24px;
            font-size: 0.75rem;
          }
        }
        &-info-area {
          display: flex;
          flex-flow: column;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 30px;
          .pre-label {
            width: 150px;
          }
          .display-date-info-elm {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 24px;
            gap: 30px;
            span {
              font-size: 14px;
            }
            .display-date-pre-label {
              width: 120px;
              &-data {
                width: 100px;
                height: 22px;
              }
            }
            .pre-text {
            }
          }
        }
      }
    }
  }
  &-input-area {
    .update_date-area {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      height: 61px;

      .pre-label {
        width: 150px;
        font-size: 0.75rem;
        flex-shrink: 0;
        padding-top: 35px;
      }
      .pre-label::after {
        content: '*';
        color: #990000;
        font-size: 0.875rem;
        padding-top: 35px;
      }
      .date-display {
        width: fit-content;
        flex-shrink: 0;
        padding-top: 35px;
        margin-right: 5px;
      }

      .pre-label-area {
        width: 150px;
        font-size: 0.75rem;
        flex-shrink: 0;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .required-mark {
          color: #990000;
          font-size: 0.875rem;
        }
      }

      .date-from-to-display {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        padding-bottom: 24px;
      }
    }

    .update_date-gift-area {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 32px;

      .label-required {
        width: 150px;
        font-size: 0.75rem;
        flex-shrink: 0;
      }
      .label-required::after {
        content: '*';
        color: #990000;
        font-size: 0.875rem;
        padding-top: 35px;
      }
      .update_date-gift {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 30px;

        .update_date-gift-info {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 50px;
        }
      }
    }

    .update_date-push-area {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 72px;

      .label-required {
        width: 150px;
        font-size: 0.75rem;
        flex-shrink: 0;
      }
      .label-required::after {
        content: '*';
        color: #990000;
        font-size: 0.875rem;
        padding-top: 35px;
      }
      .update_date-push-info {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 15px;

        div {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 50px;

          span:nth-of-type(1) {
            font-size: 14px;
          }
          span {
            width: 140px;
          }
        }
      }
    }

    .tc-input-wrapper .pre-label:not(.tc-range-picker .pre-label) {
      min-width: 150px;
    }

    .cus-input-line .input-textarea {
      .cus-pre-label {
        min-width: 150px;
      }
    }

    .cus-input-line.input-images {
      .cus-pre-label {
        min-width: 150px;
      }
    }

    .code-area {
      display: flex;
      align-items: center;
      margin-top: 30px;

      .pre-label {
        min-width: 150px;
        font-size: 0.75rem;
      }
      .pre-text {
        font-size: 14px;
        padding-top: 1px;
      }
    }

    .date-area {
      display: flex;
      align-items: center;
      position: relative;

      .advent-calendar-modal_date-area {
        .tc-range-picker-line {
          .tc-range-picker-preLabel {
            width: 150px;
          }
        }
      }
    }

    .date-area-with-btn {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .advent-calendar-modal_date-range-area {
        width: fit-content;
        .tc-range-picker-line {
          .tc-range-picker-container {
            width: 100%;
            div {
              .tc-date-picker-wrapper {
                .tc-date-picker-line {
                  width: 143px;
                }
              }
              .tc-time-picker-wrapper {
                .tc-time-picker-line {
                  width: 143px;
                }
              }
            }
          }
          .tc-range-picker-preLabel {
            width: 150px;
            flex-shrink: 0;
            div {
              font-size: 0.75rem;
            }
            .required-mark {
              color: #990000;
              font-size: 0.875rem;
            }
          }
        }
      }

      .tc-btn {
        margin: 28px 0 0 10px;
        width: 80px;
        height: 32px;
      }
    }

    .advent-calendar-date-area {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 30px;
      position: relative;
      .advent_calendar_error {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: calc(501.5px + 150px + 175px);
        position: absolute;
        top: -12px;
        color: #990000;
        font-size: 0.675rem;
        height: 10px;
      }

      .advent-calendar-gift-area {
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
        flex-shrink: 0;
        gap: 30px;

        .tc-select-wrapper {
          .tc-select-label-area {
            width: 175px;
            flex-shrink: 0;
          }
        }
        .tc-select-line {
          width: 100%;
        }
      }

      .is-disabled-label {
        label {
          color: #837f80;
        }
      }

      &-label {
        width: 150px;
        height: 32px;
        padding-bottom: 8px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        flex-shrink: 0;

        label {
          font-size: 0.75rem;
          color: #393939;
          line-height: 18px;
        }
        label::after {
          content: '*';
          color: #990000;
          font-size: 0.875rem;
        }
      }

      .tc-select-wrapper {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }

    .advent-calendar-date-area {
      line-height: 52px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      .cus-input-line.input-checkbox {
        margin-top: 0;
      }
    }
  }

  &-modal {
    .advent-calendar-push-area {
      display: flex;
      align-items: flex-start;
      margin-top: 30px;
      height: 95px;

      .label-required {
        width: 150px;
        font-size: 0.75rem;
        color: #393939;
      }
      .label-required::after {
        content: '*';
        color: #990000;
        font-size: 0.875rem;
      }
      .advent-calendar-push-btn-area {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: 60px;
        gap: 15px;

        .cus-input-line {
          margin: 0 0;
          gap: 50px;

          .tc-radio-pre-label {
            width: 140px;
            font-size: 14px;
          }
        }
      }
    }
    &_new_input_area {
      .tc-input-wrapper,
      .tc-select-wrapper {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 30px;
        position: relative;

        .tc-input-label-area,
        .tc-select-label-area {
          width: 150px;
          flex-shrink: 0;

          .tc-input-error {
            position: absolute;
            right: 0;
            top: -15px;
          }
        }

        .tc-input-line {
          width: 100%;
        }

        .tc-select-line {
          width: 270px;
          flex-shrink: 0;
        }
      }
    }

    .image-area {
      display: flex;
      flex-direction: row;
      .pre-label {
        margin-top: 5px;
        width: 150px;
        min-width: 150px;
        display: inline-block;
        font-size: 0.75rem;
        &.label-required::after {
          content: '*';
          color: #990000;
          font-size: 0.875rem;
        }
      }
      .image-select {
        position: relative;
        height: 150px;
        width: 150px;
        background-color: $grey;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        border-radius: 2px;
        overflow: hidden;
        .tc-btn {
          width: 118px;
          border-width: 2px;
          margin: 0;
          margin-bottom: 17px;
          font-size: $font-size-ssm;
        }
        .close-icon {
          position: absolute;
          right: 3px;
          top: 3px;
          font-size: 18px !important;
          z-index: 1;
        }
      }
    }
  }

  &-image-display-area {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 30px;

    .image-area-error {
      color: #990000;
      font-size: 0.625rem;
      width: 300px;
      text-align: right;
    }
  }
}
