@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.page-title-icon {
  width: 20px;
  height: 20px;
}

.page-title {
  display: flex;
  margin-top: 30px;
  .page-title-left {
    display: flex;
    align-items: center;
  }
  .title {
    margin: 0 12px 0 5px;
    font-size: $font-size-xl;
    font-weight: bold;
  }
  .sub-info {
    margin: 0 10px;
    font-size: $font-size-ssm;
    align-self: flex-end;
    margin-bottom: 1px;
  }
}
