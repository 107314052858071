@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.privacy-container {
  margin: 0 150px !important;
  .inner {
    margin: 30px 0;
  }
}
