@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.recovery-page {
  @include content-center();
  .recovery-form {
    @include border-shadow-box();
    padding-inline: 100px;
    .input-container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
    .btn-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 48px;
      .tc-btn {
        margin: 0;
      }
    }
  }
}
