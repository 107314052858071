@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.rich-text-editor-area {
  margin-top: 8px;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $grey;
  @include shadow-box();

  .editor-wider {
    background-color: red !important;
  }

  font-family: 'ＭＳ Ｐゴシック', 'Lato', 'Noto Sans JP', 'ヒラギノ角ゴ ProN',
    'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, 'MS PGothic', sans-serif;
}

.rich-text-editor-header {
  max-height: 300px;
  //padding-right: 50px;
  padding: 0 20px;
  height: 45px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  .rich-text-icons {
    .anticon {
      padding: 2px;
    }

    .rich-text-icon-active {
      background-color: $white;
    }

    padding-right: 20px;
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .select-font-size {
    height: 30px;
    width: 120px;
    margin: 10px 10px 5px 20px;
    font-size: $font-size-sssm;
  }

  .rich-text-icons + .nf-btn {
    width: 90px;
    margin-right: 30px;
    font-size: $font-size-sssm;
  }

  #font-size-select-area {
    position: relative;
    background-color: #ffffff;
    //margin: 0 15px;
    width: 120px;
    padding-left: 10px;

    .font-size-placeholder {
      padding: 2px 4px 2px 0;
      margin: 3px;
      cursor: pointer;
      font-size: 0.75rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #808080;
    }
  }
}

.revert-icon {
  display: flex;
  position: relative;
  flex-direction: column;
  user-select: none;
  align-items: center;
  right: 5px;
  top: 1px;
  width: 32px;
  height: 32px;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }

  span.icon-text {
    @include scale-font-size(0.6, center top);
    font-weight: bold;
    position: absolute;
    margin-top: 3px;
    bottom: 1px;
  }
}

.element-link {
  display: inline;
  position: relative;
}

.link-modal-wrapper {
  margin: 30px 15px 0;

  .link-modal-header {
    margin-bottom: 10px;
  }

  .text-remind {
    text-align: right;
    font-size: $font-size-sssm;
    color: $dark-grey;
    font-weight: normal;
  }
}

.element-link .popup {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  background-color: white;
  padding: 6px 10px;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid lightgray;
}

.element-link .popup a {
  display: flex;
  align-items: center;
  gap: 5px;
  padding-right: 10px;
  // border-right: 1px solid lightgrey;
}

.element-link .popup button {
  border: none;
  background: transparent;
}

.element-link .popup button:hover {
  color: rebeccapurple;
  cursor: pointer;
}

.image-block-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  overflow: hidden;
  padding: 1px;

  .image-block-inner {
    &:hover {
      cursor: pointer;
    }

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    &.image-block-active {
      box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 1px;
    }

    &.image-block-disable,
    &.image-block-disable .nf-btn {
      cursor: not-allowed !important;
    }
  }

  $block-container-width: 202px;
  $block-container-height: 200px;
  $device-mode-scale: 0.55;

  .block-action-container,
  .block-action-container .block-img,
  .block-action-container .product-info {
    width: $block-container-width;
    height: $block-container-height;
    margin: 1px;
    position: relative;
  }

  .close-icon,
  .edit-icon {
    position: absolute;
    right: 3px;
    top: 3px;
    font-size: 18px !important;
    z-index: 100;
  }
  .edit-icon {
    right: 30px;
  }

  .block-action-container {
    display: flex;
    flex-direction: column;
    background-color: $grey;
    align-items: center;
    justify-content: flex-end;

    & > video,
    & > iframe {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
    }

    .nf-btn {
      border-width: 1px;
      margin: 0;
      margin-bottom: 16px;
    }

    &.block-action-container-plus,
    &.block-action-container-plus .block-img,
    &.block-action-container-plus .product-info {
      width: 2 * $block-container-width;
      height: 2 * $block-container-height;
    }

    &.block-action-container-device,
    &.block-action-container-device .block-img,
    &.block-action-container-device .product-info {
      width: $device-mode-scale * $block-container-width;
      height: $device-mode-scale * $block-container-height;

      &.block-action-container-plus,
      &.block-action-container-plus .block-img,
      &.block-action-container-plus .product-info {
        width: $device-mode-scale * $block-container-width * 2;
        height: $device-mode-scale * $block-container-height * 2;
      }

      .nf-btn {
        font-size: $font-size-sssm;
        margin-bottom: 8px;
        @include scale-font-size(0.7, bottom center);
      }
    }
  }
}

.device-mode {
  .tc-checkbox-label {
    font-size: 0.7rem;
  }
}

.sketch-picker {
  & > div:nth-child(1) {
    &:hover {
      cursor: pointer;
    }
  }
  .flexbox-fix:nth-child(2),
  .flexbox-fix:nth-child(3) {
    display: none !important;
  }
}
