@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.preview-modal {
  .ant-modal-body {
    min-height: 667px;
    max-height: 667px;
    white-space: pre-wrap;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .ant-modal-body::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  .ant-modal-body::-webkit-scrollbar-thumb {
    background: $dark-grey;
    border-radius: 5px;
  }
  .ant-modal-body::-webkit-scrollbar-track {
    background: lighten($light-grey, 17%);
    border-radius: 5px;
  }
}
