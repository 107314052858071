@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.tc-input-password-wrapper {
  font-size: $font-size-ssm;
  .tc-input-password-preLabel {
    min-width: 130px;
    display: inline-block;
    &.label-required {
      @include requiredItem();
    }
    &.label-required-disabled {
      @include requiredDisabledItem();
    }
    &.label-disabled {
      color: $tc-gray-l1;
    }
  }
  .tc-input-password-label-area {
    align-items: baseline;
    margin-bottom: 5px;
    min-height: 24px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    &.flex-end {
      justify-content: flex-end;
    }
    .tc-input-password-label {
      color: $tc-black;
      line-height: 1.5;
      &.label-bold {
        font-weight: bold;
      }
      &.label-disabled {
        color: $tc-gray-l1;
      }
      &.label-required {
        @include requiredItem();
      }
    }
    .tc-input-password-error {
      color: $tc-red;
      font-size: $font-size-sssm;
    }
  }
  .tc-input-password-line {
    align-items: center;
    display: flex;
    .prefix {
      margin-right: 10px;
      &.label-disabled {
        color: $tc-gray-l1;
      }
      &.label-required {
        @include requiredItem();
      }
    }
    .suffix {
      margin-left: 10px;
      &.label-disabled {
        color: $tc-gray-l1;
      }
      &.label-required {
        @include requiredItem();
      }
    }
    .uneditable {
      font-size: $font-size-sm;
    }
    ::placeholder {
      transform: translateY(1px);
    }

    .ant-input-password {
      border: 1px solid $tc-gray-d1 !important;
      border-radius: 5px;
      background-color: $tc-white;
      height: 32px;
      &.ant-input-affix-wrapper-disabled {
        background-color: $tc-gray-l3 !important;
        border: none !important;
      }
    }
    input {
      font-size: $font-size-sm;
      // border-radius: 5px;
      // border-color: $tc-gray-d1 !important;
      border: none;
      &::placeholder {
        font-size: $font-size-ssm;
      }
      &.ant-input-password[disabled] {
        background-color: $tc-gray-l3;
        // border: none !important;
      }
    }
  }
}
