@mixin maxRows($rowNum: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $rowNum;
  -webkit-box-orient: vertical;
}

@mixin shadow-box($radius: 5px) {
  border-radius: $radius;
  box-shadow: 0px 0px 10px $shallow-grey;
}

@mixin border-shadow-box() {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 24px #2424241a;
  border-radius: 12px;
  text-align: center;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin content-center() {
  width: auto;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-column-center() {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin requiredItem() {
  &::after {
    content: '*';
    color: $tc-red;
    font-size: $font-size-sm;
  }
}

@mixin requiredDisabledItem() {
  &::after {
    content: '*';
    color: $tc-gray-l1;
    font-size: $font-size-sm;
  }
}

@mixin scale-font-size(
  $scale,
  $transform-origin: left bottom,
  $display: inline-block
) {
  font-size: $font-size-sssm;
  -webkit-transform: scale($scale);
  transform: scale($scale);
  transform-origin: $transform-origin;
  display: $display;
}
