@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.login-page {
  @include content-center();
  .logo-icon {
    margin-bottom: 30px;
  }
  .login-form {
    @include border-shadow-box();
    padding-inline: 100px;
    .input-container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
    .recovery-link {
      font-size: $font-size-sm;
      margin-top: 16px;
      width: 100%;
      text-align: right;
      display: inline-block;
      a {
        text-decoration: underline;
        color: $silver;
      }
    }
    .tc-btn {
      margin-top: 32px;
      margin-bottom: 0px;
    }
  }
}

.recovery-page,
.login-page {
  display: flex;
  flex-direction: column;
  position: relative;
  .footer {
    position: absolute;
    // bottom: 10px;
    z-index: 500;
    margin-top: -45px;
    bottom: 1px;
  }
}
