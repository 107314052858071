@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.enquete-modal {
  .pre-label {
    min-width: 130px;
    font-size: $font-size-ssm;
    display: inline-block;
    &.label-required {
      margin-bottom: 2px;
      @include requiredItem();
    }
  }
  .cus-input-line {
    .enquete-content {
      margin-top: 5px;
    }
    &.input-checkbox {
      margin-top: 15px;
    }
  }
  .image-area {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    .pre-label {
      margin-top: 5px;
    }
    .image-select {
      position: relative;
      height: 150px;
      width: 150px;
      background-color: $grey;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      border-radius: 2px;
      overflow: hidden;
      .tc-btn {
        width: 118px;
        border-width: 2px;
        margin: 0;
        margin-bottom: 17px;
        font-size: $font-size-ssm;
      }
      .close-icon {
        position: absolute;
        right: 3px;
        top: 3px;
        font-size: 18px !important;
        z-index: 1;
      }
    }
  }
  .input-fixed-phrase {
    margin-top: 0;
  }
  .space-left {
    margin-left: 10px;
  }
  .date-area {
    display: flex;
    margin-top: 30px;
    align-items: center;
    position: relative;
    .date-area-error {
      font-size: $font-size-sssm;
      position: absolute;
      top: -20px;
      color: $cardinal;
      &.from {
        left: 260px;
      }
      &.to {
        left: 550px;
      }
      &.update {
        left: 400px;
      }
    }
  }
  .code-area {
    margin-top: 30px;
    align-items: center;
  }
  .check-area {
    align-items: center;
  }

  .push-area {
    height: 35px;
    display: flex;
    align-items: center;
    margin-top: 29px;
    margin-bottom: -20px;
  }
}

.enquete-reference-modal {
  .pre-label {
    min-width: 130px;
    font-size: $font-size-ssm;
    display: inline-block;
    &.label-required {
      @include requiredItem();
    }
  }
  .image-area {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    .pre-label {
      margin-top: 5px;
    }
    .image-select {
      position: relative;
      height: 150px;
      width: 150px;
      background-color: $grey;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      border-radius: 2px;
      overflow: hidden;
      .tc-btn {
        width: 118px;
        border-width: 2px;
        margin: 0;
        margin-bottom: 17px;
        font-size: $font-size-ssm;
      }
      .close-icon {
        position: absolute;
        right: 3px;
        top: 3px;
        font-size: 18px !important;
        z-index: 1;
      }
    }
  }

  .check-area {
    display: flex;
    margin-top: 30px;
    align-items: center;
    .space-left {
      margin-left: 10px;
    }
    .option-list {
      display: flex;
      .radio-left {
        margin-left: 70px;
      }
    }
    .font-sm {
      font-size: $font-size-sm;
    }
  }
  .chart-legend-area {
    display: flex;
    margin-top: 10px;
    align-items: center;
    .pre-label {
      min-width: 110px !important;
    }
    .chart-legend {
      width: calc(100% - 110px);
    }
  }
  .question-border {
    margin-top: 30px;
    border: 1px solid #c4c2c3;
    border-radius: 8px;
    .question-input-area {
      padding-inline: 20px;
      padding-bottom: 30px;
      .pre-label {
        min-width: 110px !important;
      }
      .answer-area {
        margin-top: 35px;
        margin-bottom: 0px;
        display: flex;
        align-items: start;
        .pre-label {
          margin-top: 3px;
        }
        .answer-area-item {
          max-width: calc((100% - 130px));
          font-size: $font-size-sm;
        }
      }
    }
  }
  .table-area {
    margin-top: 20px;
    overflow: hidden;
    border-radius: 2px 2px 0 0;
    .tc-table-header {
      white-space: nowrap;
    }

    .ant-table-content {
      &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: $dark-grey;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 5px;
      }
    }
  }

  .summary-area {
    display: flex;
    align-items: center;
  }

  .answer-column {
    min-width: 160px;
    white-space: nowrap;
  }
  .graph-area {
    display: flex;
    margin-top: 30px;
    width: 100%;
    position: relative;
    .chart-area {
      display: flex;
      overflow: auto;
      width: 80%;
      margin-top: 20px;
      flex-direction: column;
      &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: $dark-grey;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 5px;
      }
    }
  }
  .btn-icon {
    margin-left: 10px;
  }
}

.question-list {
  .question-border {
    margin-top: 30px;
    border: 1px solid #c4c2c3;
    border-radius: 8px;
    .question-input-area {
      padding-inline: 20px;
      padding-bottom: 30px;
      .tc-input-preLabel,
      .tc-range-picker-preLabel,
      .tc-select-preLabel {
        min-width: 110px !important;
      }
      .pre-label {
        min-width: 110px !important;
        &.label-required {
          @include requiredItem();
        }
      }
      .check-area {
        display: flex;
        margin-top: 15px;
        height: 52px;
        position: relative;
        .input-error {
          font-size: $font-size-sssm;
          position: absolute;
          top: 0;
          left: 200px;
          color: $cardinal;
        }
      }
      .answer-area {
        margin-top: 35px;
        margin-bottom: 0px;
        display: flex;
        align-items: start;
        .answer-area-item {
          margin-top: 0;
          max-width: calc((100% - 130px));
        }
      }

      .cus-pre-label {
        width: 110px;
        min-width: 110px;
      }
      .image-area {
        display: flex;
        flex-direction: row;
        margin-top: 0px;
        .image-select {
          position: relative;
          height: 150px;
          width: 150px;
          background-color: $grey;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          border-radius: 2px;
          overflow: hidden;
          .tc-btn {
            width: 118px;
            border-width: 2px;
            margin: 0;
            margin-bottom: 17px;
            font-size: $font-size-ssm;
          }
          .close-icon {
            position: absolute;
            right: 3px;
            top: 3px;
            font-size: 18px !important;
            z-index: 1;
          }
        }
      }
    }

    .delete-btn-area {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
      margin-right: 10px;
      min-height: 26px;
    }
  }

  .add-btn-area {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    .plus-icon {
      width: 28px;
      height: 28px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.answer-list {
  margin-top: 10px;
  .tc-input-info {
    height: 15px;
    margin-bottom: 5px;
    display: flex;
    justify-content: flex-end;
  }
  .tc-input-error {
    color: $cardinal;
    font-size: $font-size-sssm;
    margin-right: 33px;
  }
  .pre-label {
    margin-top: 5px;
    &.label-required {
      @include requiredItem();
    }
  }
  .tc-input-line {
    display: flex;
    .tc-input-list {
      display: flex;
      flex-direction: column;
      width: 100%;
      .tc-input-list-item {
        display: flex;
        align-items: center;
        &.not-end {
          margin-bottom: 10px;
        }
        .btn-area {
          margin-left: 5px;
          height: 32px;
          .icon {
            margin-top: 2px;
            width: 28px;
            height: 28px;
            &:hover {
              cursor: pointer;
            }
          }
          .icon-x {
            margin-top: 6px;
            margin-right: 8px;
            width: 20px;
            height: 20px;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.chart-modal {
  .fade-in-center {
    animation: fade-in-center 200ms;
  }
  .fade-out-center {
    animation: fade-out-center 200ms;
  }
  @keyframes fade-in-center {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-out-center {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .modal-content {
    position: absolute;
    width: 80%;
    border-radius: 8px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 24px #2424241a;
    padding: 15px 25px;

    .chart-area {
      overflow: auto;
      height: 400px;
      max-width: 100%;
      padding: 10px;
      &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: $dark-grey;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 5px;
      }
    }

    .button-container {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }
  }
}
