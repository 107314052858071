@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dualListBox-container {
  display: flex;
  flex-direction: column;
  .ant-input-affix-wrapper {
    background-color: white;
  }
  .tc-input-info {
    display: none;
  }
}
.tc-dualListBox {
  display: flex;
  align-items: center;
  font-size: $font-size-ssm;
  .select-container {
    width: 226px;
    height: 175px;
    border: 1px solid $tc-gray-d1;
    background-color: $tc-white;
    overflow-x: hidden;
    border-radius: 5px;
    padding-block: 4px;
    padding-inline: 6px;
    &:focus {
      transition: box-shadow 0.2s;
      box-shadow: 0 0 0 1px black;
    }
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: $dark-grey;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 5px;
    }
    .dropdown-option {
      display: flex;
      align-items: center;
      height: 24px;
      padding-inline: 6px;
      &.selected {
        background-color: $tc-gray-l2;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  .actions-container {
    height: 100%;
    width: 180px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    .tc-btn {
      margin: 0px;
    }
  }
  .brand_select {
    width: 100%;
    font-size: $font-size-ssm;
    background-color: $tc-white;
    border-radius: 5px;
    .ant-select-selection-item {
      text-overflow: ellipsis;
      border: 1px solid transparent;
    }
    &.ant-select-focused {
      transition: box-shadow 0.2s;
      border-color: $tc-gray-d1 !important;
      box-shadow: 0 0 0 1px black;
    }
    .ant-select-selector {
      border-color: $tc-gray-d1 !important;
      border-radius: 5px;
      background-color: transparent !important;
    }
    .ant-select-clear {
      background-color: $tc-white !important;
    }
    .ant-select-arrow {
      background-color: transparent;
    }
    &.select-disabled {
      background-color: $tc-gray-l3;
      .ant-select-selector {
        border: 1px solid transparent !important;
      }
    }
  }
}
