@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.icon-x {
  margin-top: 8px;
  width: 16px;
  height: 16px;
  &:hover {
    cursor: pointer;
  }
}
.action-list-area {
  width: calc(100% - 100px);
  .tc-input-error-wrapper {
    margin-top: 5px;
    text-align: end;
    .tc-input-error {
      color: $tc-red;
      font-size: $font-size-sssm;
    }
  }
  .add-btn-area {
    margin-right: 0;
  }
}
.action-area {
  margin: 20px 0 0 0;
  .ant-card-bordered {
    border-radius: 3px;
  }
  .ant-card-body {
    .tc-input-preLabel,
    .tc-select-preLabel {
      min-width: 10%;
      text-align: right;
      margin-right: 10px;
    }
    .tc-input-error-wrapper {
      height: 24px;
      text-align: end;
      .tc-input-error {
        color: $tc-red;
        font-size: $font-size-sssm;
      }
    }
    .ant-input-textarea {
      border: 1px solid $tc-gray-d1;
      border-radius: 3px;
      font-size: $font-size-sssm;
      .ant-input {
        font-size: $font-size-ssm;
        margin-bottom: 3px;
      }
    }
  }
  .checkbox-list {
    margin: 8px;
  }
  .ant-card-head {
    .ant-card-head-wrapper {
      height: 36px;
    }
  }
}
