@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.footer {
  height: 20px;
  // width: 100vw;
  background-color: #00000000;
  text-align: center;
  font-size: $font-size-sm;
  position: relative;
  // bottom: 10px;
  z-index: 500;
  padding-bottom: 25px;
  margin-top: -45px;
  div {
    font: normal normal normal 12px/17px Noto Sans CJK JP;
    letter-spacing: 2.64px;
    color: #c4a6a9;
    opacity: 1;
  }
}
