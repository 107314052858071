body {
  background-color: #fdfcfc;
  overflow-x: hidden;
  overflow-y: scroll;
  font-family: $font-family;
}

.content-body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-bottom: 25px !important;
}

@media (max-width: 820px) {
  .management-page {
    margin: 0 10px;
    align-self: center;
    display: flex;
    flex-direction: column;
    padding-top: 60px;
    padding-bottom: 20px;
  }
}

@media (min-width: 821px) {
  .management-page {
    margin: 0 60px;
    align-self: center;
    display: flex;
    flex-direction: column;
    padding-top: 60px;
    padding-bottom: 20px;
  }
}

.tenco-page {
  padding-top: 60px;
  min-height: 100vh;
}

.button-container {
  display: flex;
  justify-content: center;
  position: relative;
}

.table-cell-icon {
  width: 20px;
  height: 20px;
}
.tc-action-icon {
  &:hover {
    cursor: pointer;
  }
}

.tc-disabled-icon {
  &:hover {
    cursor: default;
  }
}

.search-form {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  position: relative;
  border-radius: 8px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 24px #2424241a;
  padding-inline: 24px;
  padding-top: 24px;
  .input-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .search-btn-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .deleted-checkbox {
      position: absolute;
      right: 24px;
    }
  }
}

.csv-modal {
  &-checkbox {
    margin-left: 10px;
  }
  &-warning {
    margin: 10px;
    margin-top: 5px;
    color: $tc-black;
    font-size: $font-size-sssm;
  }
  &-date-container {
    margin-top: 24px;
    display: flex;
    align-items: center;
  }
}

.circle-image,
.circle-image.ant-image-mask {
  border-radius: 55px;
  width: 55px;
  height: 55px;
}
// .deleted-checkbox {
//   position: absolute;
//   bottom: 25px;
//   right: 30px;
// }

.ant-modal-wrap {
  overflow-x: auto;
}
.tc-modal-content {
  .input-area {
    padding: 0 44px;
    position: relative;
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden !important;
    gap: 24px;
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: $dark-grey;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-track {
      background: lighten($light-grey, 17%);
      border-radius: 5px;
    }
    .button-container {
      margin-top: 50px;
      margin-bottom: 20px;
    }
  }
}
.ant-modal-content {
  border-radius: 12px;
}
.tc-modal {
  min-width: 800px !important;
  .ant-modal-body {
    padding: 24px 0 0 0;
  }
  .modal-title {
    margin: 24px 44px 0;
  }
}
.cus-input-line {
  height: 52px;
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.cus-pre-label {
  width: 130px;
  min-width: 130px;
  display: inline-block;
  &.label-required {
    @include requiredItem();
  }
  font-size: $font-size-ssm;
}
.input-checkbox {
  margin-bottom: -20px;
  font-size: $font-size-sm;
}
.input-zip,
.input-age,
.input-tel-fax {
  display: flex;
  .tc-input-wrapper:nth-child(n + 2) {
    .pre-label {
      width: 30px;
      min-width: 30px;
      text-align: center;
    }
  }
}
.input-textarea {
  align-items: flex-start;
  height: 90px;
  margin-top: 30px;
  label {
    width: 130px;
  }
  textarea {
    flex: 1;
    padding: 8px 12px;
    resize: none;
    border: 1px solid $tc-gray-d1;
    border-radius: 5px;
    background-color: $tc-white;
    height: 90px;
    font-size: $font-size-sm;
    &:disabled {
      background-color: $tc-gray-l3;
      border: 1px solid transparent;
      cursor: not-allowed;
    }
    &::placeholder {
      transform: translateY(2px);
      color: $light-grey;
      font-size: $font-size-ssm;
    }
    &:focus-visible {
      outline-style: none;
      transition: box-shadow 0.2s;
      box-shadow: 0 0 0 1px $black;
    }
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: $dark-grey;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-track {
      background: lighten($light-grey, 17%);
      border-radius: 5px;
    }
  }
}
h1,
h2,
h3,
h4,
h5 {
  font-weight: bold;
}

.display-linebreak {
  white-space: pre-line;
}

.ant-input-password {
  background-color: $grey;
}

thead[class*='ant-table-thead'] th {
  background-color: $table-header-black;
  color: $white;
}

.ant-input,
.ant-select-selector,
.ant-input-password,
.ant-input-number {
  border: 1px solid transparent !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: $grey;
}

.ant-select-arrow {
  color: $black;
  background-color: $grey;
}

.ant-select-clear {
  background-color: $grey;
}

.select-icon {
  width: 13px;
  height: 13px;
}

.ant-picker-footer:not(.ant-picker-time-panel + .ant-picker-footer) {
  display: none;
}

.ant-picker-time-panel + .ant-picker-footer {
  .ant-picker-now {
    display: none;
  }
  .ant-picker-ok button {
    background-color: $white;
    color: $black;
    outline: none;
    border: 1px solid $black !important;
    box-shadow: none;
  }
}
.ant-picker-ok button[ant-click-animating-without-extra-node]:after {
  border: 0 none;

  opacity: 0;

  animation: none 0 ease 0 1 normal;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: $black;
}
.ant-picker-time-panel-cell-selected,
.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background-color: $grey !important;
}

.ant-select-item-option {
  background-color: $white !important;
  font-size: 11px;
}
.ant-picker-focused {
  outline: none;
  box-shadow: 0 0 0 1px $black;
  border: 1px solid transparent;
}
.ant-picker:hover {
  border: 1px solid transparent;
}
.ant-picker,
.ant-picker.ant-picker-disabled {
  border: none !important;
  outline: none;
}

.ant-picker-clear {
  background-color: $grey;
}
.ant-picker-focused {
  border-color: $black;
}
.ant-picker {
  background-color: $grey;
  border: 1px solid transparent;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid $black;
}

//　ページングstyle
.ant-pagination {
  margin: 30px 0 60px !important;
}
.ant-pagination-item {
  color: black;
}
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 0;
}

.ant-pagination-item-active {
  border: none !important;
  background-color: $paging-active-black;
  color: $white;
  a {
    color: $white !important;
  }
}

.ant-pagination-item a {
  color: $dark-grey;
  &:hover {
    color: $dark-grey;
  }
}
.ant-pagination-options {
  display: none !important;
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-item {
  border-radius: 5px;
  border: 1px solid black !important;
}
li.ant-pagination-item,
.ant-pagination-prev,
.ant-pagination-next {
  margin: 0 5px !important;
  .anticon {
    font-size: 10px;
  }
}
.ant-pagination-prev,
.ant-pagination-next {
  .ant-pagination-item-link {
    line-height: 10px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
}
.paging-icon {
  width: 12px;
  height: 12px;
}

.ant-pagination-item-link:hover {
  span {
    color: $dark-grey !important;
  }
}

.ant-pagination-disabled {
  display: none;
}

.ant-pagination-item-link-icon .pagination-jump-next {
  color: $dark-grey !important;
}

.ant-table-cell::before {
  content: none !important;
}

.ant-table-column-sorter {
  visibility: collapse;
}
.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
  box-shadow: none;
}
.ant-table-ping-left .ant-table-cell-fix-left-first::after,
.ant-table-ping-left .ant-table-cell-fix-left-last::after {
  box-shadow: none;
}
.ant-table-ping-right:not(.ant-table-has-fix-right)
  .ant-table-container::after {
  box-shadow: none;
}
.ant-table-ping-right .ant-table-cell-fix-right-first::after,
.ant-table-ping-right .ant-table-cell-fix-right-last::after {
  box-shadow: none;
}
.ant-table-cell {
  white-space: pre-wrap;
}

.ant-input,
.ant-select-selector,
.ant-input-password,
.ant-input-number {
  border: 1px solid transparent !important;
}

.ant-input:focus,
.ant-input-focused,
.ant-input-affix-wrapper-focused,
.ant-input-number:focus,
.ant-input-number-focused {
  // border-color: $black !important;
  box-shadow: 0 0 0 1px $black;
}
.ant-input-number-input {
  // padding: 0 11px;
  padding: 0 0;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  box-shadow: 0 0 0 1px $black;
}

.ant-modal-wrap {
  text-align: center;
  .ant-modal {
    top: 0;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }
}

.ant-modal-wrap::before {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: '';
}

.ant-modal-header {
  border-bottom: none;
}

.ant-modal-mask {
  background-color: rgba(150, 150, 150, 0.9);
  filter: blur(5px);
}

img {
  object-fit: contain;
}

.rich-text-editor-area {
  span {
    font-family: $font-family;
  }
}

.nf-btn,
.tc-btn {
  font-family: $font-family;
}
.editor-detail {
  width: 100%;
}
.editor-detail-title {
  align-self: flex-start;
}
